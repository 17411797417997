<template>
  <div>
    <el-container style="padding-left: 10%;">
      <el-aside width="12em">

        <el-row style="top: 30px">
          <el-col >
            <el-tooltip class="item" effect="dark" content="距离地面5厘米" placement="top">
              <span style="font-size: 25px">TVOC - SOIL:</span>
            </el-tooltip>
          </el-col>
          <!--          <el-col :span="10">-->
          <!--            <div><img src="../assets/TVOC-Outlined.png"></div>-->
          <!--          </el-col>-->
          <!--        </el-row>-->
          <!--        <el-row style="padding-left: 23px;">-->
          <!--          <el-col :span="16"></el-col>-->
        </el-row>
      </el-aside>
      <el-main>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="selector">
              <el-select v-model="value" placeholder="请选择有机物">
                <el-option
                    v-for="item in vocs"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="16">
            <div class="voc-data"><span>{{
                voc_soil !== -1 ? parseInt(voc_soil) : ''
              }}</span>{{ voc_soil !== -1 ? '&nbspmmol/L' : '无数据' }}
            </div>
          </el-col>
        </el-row>


      </el-main>
    </el-container>

  </div>
</template>

<script>
export default {
  name: "Voc",
  props: ['voc_soil'],
  data() {
    return {
      vocs: [{
        value: 'isobutene',
        label: '异丁烯'
      }, {
        value: 'benzene',
        label: '苯'
      }],
      value: 'benzene'
    }
  }
}
</script>

<style lang="less">
.voc-data {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 40px;
  font-size: 35px;

  span {
    font-size: 50px;
    color: #00c0ff;
    //margin-right: 15px;
  }
}

.selector {
  .el-select {
    width: 100px;
    height: 40px;
    margin-left: 11px;

    .el-input__inner {
      color: #fff;
    }


    .el-input--suffix {
      height: 100%;

      > input {
        height: 100%;
        border: 1px solid #0067B1;

        background-color: transparent;
      }

      .el-input__suffix {
        height: 40px;
        //top: -6px;

        .el-icon-arrow-up:before {
          content: "";
          color: #98dcff;
        }
      }
    }
  }

}
</style>