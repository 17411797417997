<template>
<!--  <div class="left-chart-1">-->
<!--&lt;!&ndash;    <div class="lc1-header">浏览器用户</div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="lc1-details">在线数<span>{{ onlineBrowserUserNum }}</span></div>&ndash;&gt;-->
<!--&lt;!&ndash;    <dv-capsule-chart class="lc1-chart" :config="config"/>&ndash;&gt;-->
<!--&lt;!&ndash;    <dv-decoration-2 style="height:10px;"/>&ndash;&gt;-->
<!--    <p>实时监控界面：</p>-->
<!--    <el-image-->
<!--        style="width: 240px; height: 220px"-->
<!--        :src="monitorUrl"-->
<!--        fit="fill"></el-image>-->

<!--  </div>-->
</template>

<script>
import request from "@/utils/request";


export default {
  name: "RightTopChart",
  // props:['monitorUrl'],
  data() {
    return {
      onlineBrowserUserNum: 0,
      config: {
        data: [
        ],
        colors: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b'],
        unit: '个',
        showValue: true
      },
    }
  },
  mounted() {
    // this.getConnectionBrowserUser();
    this.getVariousNums();
  },
  methods: {
    // getConnectionBrowserUser: function () {
    //   request.get('/connection/browserUser').then(res => {
    //     this.onlineBrowserUserNum = Object.keys(res.data).length;
    //   })
    // },
    getVariousNums: function () {
      request.get('/tasks/taskVariousNums').then(res => {
        this.config.data = [{name: '任务总量', value: res.data.taskNum},{name: '任务完成情况', value: res.data.finishedNum}]
        // this.config.data = [{name: '任务完成数', value: res.data.finishedNum}]
        this.config = {...this.config};
      })
    },
  }
}
</script>

<style lang="less">
.left-chart-1 {
  //width: 100%;
  height: 35%;
  display: flex;
  margin-left: 20px;
  flex-direction: column;

  .lc1-header {
    height: 20px;
    line-height: 20px;
    text-indent: 20px;
    margin-top: 10px;
    font-size: larger;
    font-weight: bold;
  }

  .lc1-details {
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }

  }

  .lc1-chart {
    flex: 1;
  }

}
</style>