import Vue from 'vue'
// import AMapLoader from '@amap/amap-jsapi-loader'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue'
import dataV from '@jiaminghi/data-view'
import router from './router'
import './assets/common.less'
import * as echarts from 'echarts';
Vue.use(dataV);
Vue.use(ElementUI);

Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts
// Vue.use(AMapLoader);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
