<template>
  <dv-border-box-9 class="ocr-box">
    <el-container style="width: 100%;height: 100%" id="ocr-border">
      <el-header height="30px" id="ocr-header" style="margin: 0;padding-top: 15px">识别结果</el-header>
      <el-main style="padding: 5px">
        <div id="ocr-table">
          <el-table :data="ocrTable" @cell-dblclick="handle">
            <el-table-column prop="name" label="元素" width="150" align="center">
              <template slot-scope="scope">
                <div v-if="!scope.row.isEdit">{{ scope.row.name }}</div>
                <div v-else>
                  <el-input v-model="scope.row.name"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="value" label="检测值（PPM）" width="200" align="center">
              <template slot-scope="scope">
                <div v-if="!scope.row.isEdit">{{ scope.row.value }}</div>
                <div v-else>
                  <el-input v-model="scope.row.value"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                label="操作"
                width="100"
                align="center">
              <template slot-scope="scope">
                <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small">
                  {{ scope.row.isEdit ? '完成' : '编辑' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </el-main>
    </el-container>
  </dv-border-box-9>

</template>

<script>
export default {
  name: "DynamicTable",
  data() {
    return {
      ocrTable: []
      // tableHeader: {
      //   name: "元素",
      //   value: "检测值（PPM）",
      //
      // },
      // tableData: [{
      //   name: 'Fe',
      //   value: '1518',
      // }, {
      //   name: 'W',
      //   value: '216',
      // }, {
      //   name: 'V',
      //   value: '501',
      // }, {
      //   name: 'Ca',
      //   value: '2016',
      // },
      //   {
      //     name: 'Cr',
      //     value: '2016',
      //   },
      //   {
      //     name: 'Al',
      //     value: '2016',
      //   }]
    }
  },
  watch: {
    ocrTable: function (curOcrTable) {
      this.$emit('update', curOcrTable);
    },
    // metalData:function (curMetalData){
    //   this.$emit('update', curMetalData);
    // }
  },
  beforeMount() {
    this.$emit('update', this.ocrTable);

  },
  methods: {
    handleClick(row) {
      // 动态设置数据并通过这个数据判断显示方式
      if (row.isEdit) {
        // 2022.8.31 更新
        // 其实我们想要取消编辑状态的话不需要用下面这步
        // 直接使用 row.isEdit = false 就行
        // 因为 this.$set 后 isEdit 字段已经被 Vue 加上了响应式
        this.$delete(row, 'isEdit')
        // console.log(row)
      } else {
        // 点击编辑后，将标识符设为true
        this.$set(row, 'isEdit', true);
        // console.log(1)
      }
      // console.log(this.tableData);
    },
    handle(row, column, cell, event) {
    }
  }

}
</script>

<style lang="less">
.ocr-box {
  //width: 280px;
  //height: 300px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //color: #012444;

  #ocr-border {
    display: flex;
    justify-content: center;
    text-align: center;
    //align-items: center;
    #ocr-header {
      color: #00ccff;
      margin-top: 10px;
      padding-top: 10px;
      letter-spacing: 6px;
    }

    #ocr-table {
      display: flex;
      height: 90%;
      width: 90%;
      margin-left: 15px;
      //表格间距
      .el-table--mini .el-table__cell {
        padding: 4px 0
      }

      //整个表格背景颜色及边框弧度
      .el-table {
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 9px;
      }

      //字体颜色
      .el-table tr {
        background-color: transparent;
        color: white;
      }

      //标题背景颜色
      .el-table th {
        background-color: transparent !important;
      }

      //标题字体颜色
      .el-table th > .cell {
        color: white;
      }

      //去除边框
      .el-table--border {
        border: none;
      }

      //去除行下划线
      .el-table td.el-table__cell {
        border: 0;
      }

      //标题加下划线
      .el-table__header-wrapper, .el-table__footer-wrapper {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      }

      .el-table tbody tr:hover > td {
        background-color: #424244 !important;
      }

      // 如果td没有颜色，可以去掉，这里加上保险
      .el-table__body tr.hover-row > td {
        background-color: #424244;
      }

      .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
      }

      /*定义滚动条轨道 内阴影+圆角*/

      .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: 0 1px 3px #071e4a inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #071e4a; /*滚动条的背景颜色*/
      }

      /*定义滑块 内阴影+圆角*/

      .el-table__body-wrapper::-webkit-scrollbar-thumb {
        box-shadow: 0 1px 3px #00a0e9 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #00a0e9; /*滚动条的背景颜色*/
      }
    }
  }


}
</style>