<template>

  <div id="WindDirection-chart">
    <div class="WindDirection-chart-title">风向和风力</div>
    <!--      </el-header>-->
    <!--      <el-main style="padding: 0">-->
    <div class="chart-container" :key="changeTimes">
      <div id="windGauge"></div>
    </div>
    <!--      </el-main>-->
    <!--    </el-container>-->
  </div>

</template>

<script>
export default {
  name: "WindDirection",
  props:['windDegree','windDirection'],
  data() {
    return {
      chart:null,
      changeTimes:0,
      option: {
        series: [{
          name: '方向表',
          type: 'gauge',
          detail: {
            formatter: '{value}°',
            color:'#FFF'
          },

          startAngle: 90,
          splitNumber: 8,
          endAngle: 450,
          min: 0,
          max: 360,
          axisTick: {
            distance: -15,
            length: 4,
            lineStyle: {
              color: '#fff',
              width: 2
            }
          },
          splitLine: {
            distance: -15,
            length: 10,
            lineStyle: {
              color: '#fff',
              width: 2
            }
          },
          pointer: {
            itemStyle: {
              color: '#94c0fc'
            }
          },
          axisLabel: {
            color:"#FFF",
            fontSize: 15,
            distance: -25,
            formatter: function (value) {
              if (value === 0) {
                return '北';
              } else if (value === 45) {
                return '东北';
              } else if (value === 90) {
                return '东';
              } else if (value === 135) {
                return '东南';
              } else if (value === 180) {
                return '南';
              } else if (value === 225) {
                return '西南';
              } else if (value === 270) {
                return '西';
              } else if (value === 315) {
                return '西北';
              }
            }
          },
          title:{
            color:"#FFF",
            offsetCenter:[0,"-60%"]
          },
          data: [{
            value: this.windDirection,
            name:"风力："+this.windDegree+"m/s"
          }]
        }]
      }

    }
  },
  mounted() {
    this.init();
  },
  watch:{
    windDirection:function (windDirection) {
      this.option.series[0].data[0].value=windDirection;
      this.init();
    },
    windDegree:function (windDegree) {
      this.option.series[0].data[0].name="风力："+windDegree+"m/s";
      this.init();
    },
  },
  methods: {
    init:function (){
      let oldchart = this.$echarts.init(document.getElementById('windGauge'));
      if(oldchart!=null && oldchart!="" &&oldchart!=undefined){
        oldchart.dispose();
      }
      let chart = this.$echarts.init(document.getElementById('windGauge'));
      chart.setOption(this.option,true);
      window.addEventListener('resize', function () {
        chart.resize()
      });
    },
  },
}
</script>

<style lang="less">
#WindDirection-chart {
  height: calc(39% - 26px);
  //margin-top:3%;
  //margin-left: 2%;
  width: 96%;
  //box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
  //background-color: rgba(6, 30, 93, 0.5);
  //border-top: 2px solid rgba(1, 153, 209, .5);
  box-sizing: border-box;
  padding: 0px 30px;

  .WindDirection-chart-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    letter-spacing: 4px;

  }

  .chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 9%;

    #windGauge {
      height: 250px;
      width: 250px;
    }
  }
}


</style>