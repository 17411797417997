<template>
  <div id="data-view">
    <!--    <dv-full-screen-container>-->
    <div style="position: absolute;top:42%;left: 1%;height: 19%;width: 18%" v-if="PointData.length==0">
      <h1 style="text-align: center;font-size: 20px;height: 30px;margin-top: 25%">
        暂 无 任 务
      </h1>
    </div>
    <div style="position: absolute;top:75%;left: 1%;height: 19%;width: 18%"
         v-if="HeavyMetal.length==0 && pollutionMode==enums.pollutionMode.metalMode">
      <h1 style="text-align: center;font-size: 20px;height: 30px;margin-top: 25%">
        暂 无 数 据
      </h1>
    </div>

    <top-header class="top-header"/>
    <div class="main-container">
      <dv-border-box-12 class="menu-container">
        <el-header style="text-align: center;font-size: 30px;height: 30px;margin-top: 10px;">
          任 务 控 制
        </el-header>
        <div style="display: flex;margin: auto;height: 5%;">
          <NewTask style="margin: auto;margin-top: 8%;margin-left: 9%"
                   @click.native="CreateNewTask();curMenuStatus=enums.menuStatus.taskStatus"></NewTask>
          <TaskManage style="margin: auto;margin-top: 8%;margin-left: 3%"
                      @click.native="TaskManageBottom"></TaskManage>
          <SchemeManage style="margin: auto;margin-top: 8%;margin-left: 3%"
                        @click.native="SchemeManageBottom"></SchemeManage>
        </div>
        <dv-decoration-2 style="width:90%;height:5px;padding: 8px;margin-top: 10px"/>
        <RightBottomChart ref="rightBottomChart" @drawHisTask="drawHisTask" @drawMonitor="drawMonitor"
                          @initAll="initAll" v-bind:taskId="taskId" v-bind:taskProcess="taskProcess"
                          v-bind:PointData="PointData" v-bind:AllLength="Math.round(AllLength)"
                          v-bind:TaskName="TaskName" @ChangeHeavyMetal="ChangeHeavyMetal"
                          v-bind:tasktype="pollutionMode"/>
        <dv-decoration-2 style="width:90%;height:5px;padding: 8px"/>
        <div v-if="pollutionMode==enums.pollutionMode.metalMode">
          <Contaminants v-bind:HeavyMetalData="HeavyMetal"></Contaminants>
        </div>
        <div v-if="pollutionMode==enums.pollutionMode.vocMode">
          <WindDirection v-bind:windDegree="windData.windDegree" v-bind:wind-direction="windData.windDirection"/>
        </div>

      </dv-border-box-12>
      <div class="center-container">
        <dv-border-box-12 class="map-container">
          <!--            <div style="height: 95%;width: 95%">-->
          <CenterMap style="height: 95%;width: 95%;margin: 10px auto;" ref="centerMap"
                     @update:polyline="polyline=$event"
                     @update:newTaskMarkers="newTaskMarkers=$event"
                     @update:rawMarkers="rawMarkers=$event"/>
          <!--            </div>-->

        </dv-border-box-12>
        <div class="device-control-container">
          <dv-border-box12 class="car-control-container">
            <!--              <el-container>-->
            <!--                <el-header style="padding: 20px 0 0 20px;font-size: 20px;letter-spacing: 6px;height: 50px" >车辆控制</el-header>-->
            <!--                <el-main style="padding: 0">-->
            <div class="camera-box">
              <CameraImage style="margin-top: 30px" :width="90" :height="90" :myUrl="foreGroundMonitorUrl"
                           :cameraTitle="'车辆监控界面'"/>
            </div>
            <!--                </el-main>-->
            <!--              </el-container>-->
          </dv-border-box12>
          <dv-border-box-12 class="car-status-container">
            <el-container style="height: 100%">
              <el-header style="padding: 20px 0 0 20px;font-size: 20px;letter-spacing: 6px;">车辆状态</el-header>
              <el-main style="padding: 0">
                <div id="car-status">
                  <CarSpeed style="width: 45%" :speed="carData.speed"></CarSpeed>
                  <div style="flex-direction: column;width: 50%;">
                    <Mileage :miles="carData.mileage"/>
                    <Humiture :temperature="carData.temperature" :humidity="carData.humidity"/>
                  </div>
                </div>
                <dv-decoration-2 style="width:90%;height:5px;padding: 8px"/>
                <div class="btn-box">
                  <el-row style="width: 100%;display: flex;justify-content: center;">
                    <el-col :span="8">
                      <Btn1 :text="'前进'" @click.native="forward"></Btn1>
                    </el-col>
                    <el-col :span="8">
                      <Btn1 :text="'后退'" @click.native="backward"></Btn1>
                    </el-col>
                    <el-col :span="8">
                      <Btn1 :text="'暂停'" @click.native="pause"></Btn1>
                    </el-col>
                  </el-row>
                  <el-row style="width: 100%;display: flex;justify-content: center;margin-top: 20px">
                    <el-col :span="11">
                      <Btn2 :text="'环境监控重启'" @click.native="resetRaspberry1"></Btn2>
                    </el-col>
                    <el-col :span="11">
                      <Btn2 :text="'土壤设备重启'" @click.native="resetRaspberry1"></Btn2>
                    </el-col>
                  </el-row>
                </div>
              </el-main>
            </el-container>
          </dv-border-box-12>
        </div>
      </div>
      <div class="block-content">
        <dv-border-box12 class="car-monitor-container">
          <el-container style="height: 100%">
            <el-header style="padding: 20px 0 0 20px;font-size: 20px;letter-spacing: 6px;height: 40px">车辆监控
            </el-header>
            <el-main style="padding: 0">
              <div class="camera-box">
                <CameraImage :myUrl="roadSurfaceMonitorUrl" :cameraTitle="'地面监控界面'"/>
                <CameraImage :myUrl="boardMonitorUrl" :cameraTitle="'底部监控界面'"/>
              </div>
            </el-main>
          </el-container>
        </dv-border-box12>

        <dv-border-box12 class="monitor-data-container">
          <el-container style="height: 100%">
            <el-header style="padding: 15px 0 20px 20px;font-size: 20px;letter-spacing: 6px;height: 30px">
              {{ pollutionMode === enums.pollutionMode.vocMode ? "大气监测" : "重金属监测" }}
            </el-header>
            <el-main style="padding: 0 20px">
              <div class="camera-box"
                   v-if="pollutionMode === enums.pollutionMode.metalMode || pollutionMode === enums.pollutionMode.noMode">
                <!--                <CameraImage style="width: 50%" :myUrl="XRayScreenMonitorUrl" :cameraTitle="'X荧光仪界面'"/>-->
                <DynamicTable ref="dynamicTable" @update="ocrTable=$event"
                              style="padding-left: 10px;width:100%;height: 100%"/>
              </div>

              <div class="air-box" v-if="pollutionMode === enums.pollutionMode.vocMode">
                <AirData style="height: 50%" :co2="airData.co2" :ch2o="airData.ch2o" :tvoc_air="airData.tvoc_air"
                         :pm25="airData.pm25"
                         :pm10="airData.pm10"/>
                <!--                  <dv-decoration-10 style="width:100%;height:5px;padding-left: 20px" />-->
                <dv-decoration-3 style="width:100%;height:20px;padding-left: 15px"/>
                <el-header style="padding: 15px 0 0 20px;font-size: 20px;letter-spacing: 6px;height: 30px">土壤VOC监测
                </el-header>
                <div class="voc-box">
                  <Voc :voc_soil="airData.tvoc_soil"/>
                </div>
              </div>
            </el-main>

          </el-container>

        </dv-border-box12>

        <dv-border-box-12 class="device-status-container">
          <el-container>
            <el-header style="padding: 15px 0 0 20px;font-size: 20px;letter-spacing: 6px;height: 25px">设备控制
            </el-header>
            <el-main id="device-btn-box" style="padding: 0">
              <div>
                <el-row>
                  <el-col :span="6">
                    <MyBtn :text="'下降'" :className="'flowBtn'" :color="'#00ccff'" @click.native="startLifter"></MyBtn>
                  </el-col>
                  <el-col :span="6">
                    <MyBtn style="height: 40px;width: 80px;" :className="'flowBtn'" :text="'上升'" :color="'#00ccff'"
                           @click.native="lifterUp"></MyBtn>
                  </el-col>
                  <el-col :span="6">
                    <MyBtn :text="'测量'" :className="'flowBtn'" :color="'#00ccff'"
                           @click.native="measureMetal"></MyBtn>
                  </el-col>
                  <el-col :span="6">
                    <MyBtn :text="'重拍'" :className="'flowBtn'" :color="'#00ccff'" @click.native="reGraph"></MyBtn>
                  </el-col>
                </el-row>
                <el-row style="display: flex;">
                  <el-col :span="6">
                    <MyBtn :text="'重读'" :className="'flowBtn'" :color="'#00ccff'" @click.native="reOCR"></MyBtn>
                  </el-col>
                  <el-col :span="6">
                    <MyBtn style="height: 40px;width: 80px;" :className="'flowBtn'" :text="'写入'" :color="'#00ccff'"
                           @click.native="uploadData"></MyBtn>
                  </el-col>
                  <el-col :span="6">
                    <MyBtn style="height: 40px;width: 80px;" :className="'flowBtn'" :text="'停止'" :color="'#00ccff'"
                           @click.native="stopLifter"></MyBtn>
                  </el-col>
                  <el-col :span="6">
                    <MyBtn style="height: 40px;width: 80px;" :text="'清洁'" :className="'flowBtn'" :color="'#00ccff'"
                           @click.native="cleanBoard"></MyBtn>
                  </el-col>
                </el-row>
              </div>
            </el-main>
          </el-container>

        </dv-border-box-12>
      </div>
    </div>
    <div class="pollution-switch">
      <el-radio-group v-model="pollutionMode">
        <el-radio-button label="重金属"></el-radio-button>
        <el-radio-button label="VOC"></el-radio-button>
      </el-radio-group>
    </div>
    <!--    </dv-full-screen-container>-->
    <!--    <div class="gaode-map">-->

    <!--    </div>-->
    <div class="btn-container">
      <el-button type="primary" size="small"
                 v-show="(curMode===enums.mode.createMode || curMode===enums.mode.checkMode&&curCheckModeChild!==enums.checkModeChild.noCheckMode)&& isAddPoint"
                 @click="taskDialogVisible = true">保存
      </el-button>
      <el-button type="primary" size="small"
                 @click="drawNewTaskMarker();curCheckModeChild = enums.checkModeChild.checkCreateMode"
                 v-if="curMode===enums.mode.checkMode&&curCheckModeChild===enums.checkModeChild.noCheckMode&&curMenuStatus!==enums.menuStatus.planStatus">
        新增路径点
      </el-button>
      <el-button type="primary" size="small" @click="editPolyline"
                 v-if="curMode===enums.mode.checkMode&&curCheckModeChild===enums.checkModeChild.noCheckMode&&curMenuStatus!==enums.menuStatus.planStatus">
        编辑当前路线
      </el-button>
      <el-button type="primary" size="small"
                 v-if="curMode===enums.mode.checkMode&&curCheckModeChild===enums.checkModeChild.noCheckMode"
                 @click="taskDialogVisible = true">
        执行
      </el-button>
      <el-button type="primary" size="small" @click="calcOffset"
                 v-if="curMode === enums.mode.correctCoordMode">
        计算
        <!--              &&curCheckModeChild!==enums.checkModeChild.noCheckMode-->
      </el-button>
      <el-button type="primary" size="small" @click="cancelEdit"
                 v-if="curMode===enums.mode.createMode||curMode===enums.mode.checkMode||curMode === enums.mode.correctCoordMode">
        取消
        <!--              &&curCheckModeChild!==enums.checkModeChild.noCheckMode-->
      </el-button>
    </div>

    <div class="locate-box">
      <el-button icon="el-icon-my-locate" style="width: 100%;height: 100%;padding: 0" @click="loadCarPos">
        <!--        <i class="el-icon-my-locate"  >-->
        <!--        </i>-->
      </el-button>

    </div>

    <div class="add-buildings">
      <el-button type="primary"
                 @click="getBuildingData">加载建筑数据
      </el-button>
      <el-popover
          placement="right"
          width="400"
          trigger="click"
          style="padding-left: 10px">
        <div>
          <el-container>
            <el-header height="40px" style="font-size: 20px">
              坐 标 偏 移 量
            </el-header>
            <el-main style="padding: 0 30px ;">
              <el-row :gutter="25" style="padding: 10px 0;display:flex;text-align: center;align-items: center">
                <el-col :span="8"><span>经度偏移量：</span></el-col>
                <el-col :span="15"><span style="font-size: 15px;color: #096dd9">{{ coordOffset.lngOffset }}</span>
                </el-col>
              </el-row>
              <el-row :gutter="25" style="padding: 10px 0;display:flex;text-align: center;align-items: center">
                <el-col :span="8"><span>纬度偏移量：</span></el-col>
                <el-col :span="15"><span style="font-size: 15px;color: #096dd9">{{ coordOffset.latOffset }}</span>
                </el-col>
              </el-row>
            </el-main>
            <el-button type="primary" @click="coordCorrect">{{ isCoordCorrect ? '绘 制 定 标 点' : '校 正 应 用' }}</el-button>
          </el-container>
        </div>

        <el-button slot="reference">坐标校正</el-button>
      </el-popover>
    </div>

    <div class="canvas-layer-switch" v-if="isCanvas">
      <el-button type="primary" plain @click="canvasLayerSwitch">{{
          isCanvasUpdate ? '更新插值图层' : '显示插值图层'
        }}
      </el-button>
    </div>
    <div class="grid-select" v-if="isGrid">
      <el-select v-model="gridGap" clearable placeholder="请选择格网大小" style="width: 150px" @change="changeGridGap($event)">
        <el-option
            v-for="item in gridOptions"
            :key="item.gridGap"
            :label="item.label"
            :value="item.gridGap">
        </el-option>
      </el-select>
    </div>
    <div class="kriging-layer-check-boxes" v-if="showLayers">
      <el-checkbox-group v-model="checkElementLayer" style="display: flex;flex-direction: column;">
        <!--        <div v-for="label in myLabel">-->
        <el-checkbox v-for="(label,index) in myLabel" @change="checkChange(index, label)" :label="label" :key="index">
          {{ label }}
        </el-checkbox>
        <!--        </div>-->
        <!--        <LayerCheckBox v-for="label in myLabel" :key="label" :label="label"-->
        <!--                       @check="addCheckedElementLayer" @uncheck="removeUnCheckedElementLayer"/>-->
      </el-checkbox-group>

    </div>
    <el-dialog
        title="巡 检 方 案"
        class="project-container"
        :visible.sync="projectDialogVisible"
        width="50%"
        style="padding-top: 10px"
    >
      <el-container class="table-container">
        <el-header height="40px" style="padding: 0 30px;display: flex;">
          <el-button type="primary" size="small" @click="createCarPlan">{{
              isCarPosPlanCreate ? '创建方案' : '提交方案'
            }}
          </el-button>
          <el-button type="primary" size="small" v-if="!isCarPosPlanCreate" @click="isCarPosPlanCreate=true">取消
          </el-button>
          <div class="car-position-plan" style="display: flex;font-size: 13px" v-if="!isCarPosPlanCreate">
            <el-button type="primary" size="small" style="margin: 0 20px" @click="addCarPos2Plan">获取坐标</el-button>
            <div style="display: flex;text-align: center;align-items: center">
              <span style="display: flex;padding: 0 20px;width: 100px;">
                经度：{{ carData.carPosition.length !== 0 ? carData.carPosition[0].toFixed(4) : '无' }}
              </span>
              <span
                  style="display: flex;padding: 0 20px;width: 100px;">
                纬度：{{ carData.carPosition.length !== 0 ? carData.carPosition[1].toFixed(4) : '无' }}
              </span>
            </div>
            <div style="display: flex;text-align: center;align-items: center">
              <el-popover
                  placement="right"
                  width="300"
                  trigger="click">
                <el-table :data="planData.inspectionPointList">
                  <el-table-column type="index" width="50"/>
                  <el-table-column width="150" property="longitude" label="经度"/>
                  <el-table-column width="100" property="latitude" label="纬度"/>
                </el-table>
                <el-button slot="reference">坐 标 列 表</el-button>
              </el-popover>
              <!--              <span-->
              <!--                  style="display: flex;padding: 0 20px;width: 120px;">监测点数量：-->
              <!--                {{ planData.inspectionPointList ? planData.inspectionPointList.length : 0 }}-->
              <!--              </span>-->
            </div>
          </div>

        </el-header>

        <el-main class="plan-table">
          <el-table
              class="table-style-custom"
              :data="tableData"
              style="width: 100%;"
              height="100%"
              size="small"
              border
              :default-sort="{prop: 'planId', order: 'descending'}">
            <el-table-column
                fixed
                prop="planId"
                label="id"
                width="60"
                sortable
                align="center">
            </el-table-column>
            <el-table-column
                prop="planName"
                label="方案名"
                align="center">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                align="center">
              <template slot-scope="scope">
                <el-button type="text" size="small"
                           @click="$refs.centerMap.clearDraw(true, true, true);
                           handleClick(scope.row.inspectionPointList,scope.row.planId,scope.row.planName,scope.row.offsetId,scope.row.dataType,scope.row.landType);curMenuStatus=enums.menuStatus.planStatus">
                  查看
                </el-button>
                <el-popconfirm
                    title="确认执行吗？"
                    @confirm="planData.planName=scope.row.planName;planData.inspectionPointList=scope.row.inspectionPointList;
                    curMenuStatus=enums.menuStatus.planStatus;taskDialogVisible = true;">
                  <el-button slot="reference" type="text" size="small" style="margin-left: 10px">执行
                  </el-button>
                </el-popconfirm>
                <el-popconfirm
                    title="确认删除吗？" @confirm="deletePlan(scope.row.planId)">
                  <el-button slot="reference" type="text" size="small" style="margin-left: 10px">删除
                  </el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </el-dialog>
    <el-dialog
        title="任 务 管 理"
        class="task-container"
        :visible.sync="taskManageDialogVisible"
        width="50%">
      <el-container class="table-container">
        <el-main class="plan-table">
          <el-table
              class="table-style-custom"
              :data="taskData"
              height="100%"
              style="width: 100%;"
              size="small"
              border>
            <el-table-column
                prop="taskName"
                label="任务名"
                align="center">
            </el-table-column>
            <el-table-column
                prop="createUser"
                label="发布者"
                :formatter="formatterCreateUser"
                align="center">
            </el-table-column>
            <el-table-column
                prop="taskStatus"
                label="标签"
                align="center"
                :filters="[{ text: '未完成', value: 1 }, { text: '已完成', value: 2 },{ text: '强制停止', value: 3 }]"
                :filtered-value="[1,2,3]"
                :filter-method="filterTag"
                filter-placement="bottom-end">
              <template slot-scope="scope">
                <el-tag
                    :type="scope.row.taskStatus === 1 ? 'warning' : scope.row.taskStatus ===2?'success':'danger'"
                    disable-transitions>
                  {{ scope.row.taskStatus === 1 ? '未完成' : scope.row.taskStatus === 2 ? '已完成' : '强制停止' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
                prop="dataType"
                label="污染物"
                align="center">
              <template slot-scope="scope">
                <el-tag
                    disable-transitions>
                  {{ scope.row.dataType === 0 ? 'VOC' : '重金属' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                align="center">
              <template slot-scope="scope">
                <el-button type="text" size="small"
                           @click="$refs.centerMap.clearDraw(true, true, true);
                           handleClick(scope.row.detectionPointList,scope.row.taskId,scope.row.taskName,scope.row.dataType,scope.row.landType,scope.row.offsetId,scope.row.detectionDataList);curMenuStatus=enums.menuStatus.taskStatus">
                  查看
                </el-button>
                <el-popconfirm
                    title="确认执行吗？"
                    @confirm="beforeRun(scope.row.taskId,scope.row.taskName,scope.row.detectionPointList,scope.row.dataType,scope.row.landType);curMenuStatus=enums.menuStatus.taskStatus">
                  <el-button slot="reference" type="text" size="small" style="margin-left: 10px">执行
                  </el-button>
                </el-popconfirm>
                <!--                <el-popconfirm-->
                <!--                    title="确认删除吗？" @confirm="deleteTask(scope.row.taskId)">-->
                <!--                  <el-button slot="reference" type="text" size="small" style="margin-left: 10px">删除-->
                <!--                  </el-button>-->
                <!--                </el-popconfirm>-->
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </el-dialog>
    <el-dialog
        title="任 务 名 称"
        :visible.sync="taskDialogVisible"
        width="25%">
      <el-input v-model="planData.planName" autocomplete="off" placeholder="请输入任务名称"
                clearable></el-input>
      <div style="margin-top: 10%">
        <label style="color:#096dd9;margin: 5px">土地类型：</label>
        <el-radio v-model="planData.landType" label="1">第一类土地类型</el-radio>
        <el-radio v-model="planData.landType" label="2">第二类土地类型</el-radio>
        <el-tooltip effect="dark" placement="top">
          <div slot="content">第一类用地：居住用地、公共管理和公共服务用地中的中小学用地、医疗卫生用地和社会福利设施用地，以及公园绿地中的社会公园或儿童公园用地。
            <br/>第二类用地：工业用地、物流仓储用地、商业服务业设施用地、道路与交通设施用地、公共设施用地、公共管理与公共服务用地，以及绿地与广场用地（除一类中公园绿地相关用地）
          </div>
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>

      </div>
      <div style="margin-top: 10%">
        <label style="color:#096dd9;margin: 5px">任务类型：</label>
        <el-radio v-model="planData.dataType" label="0">VOC模式</el-radio>
        <el-radio v-model="planData.dataType" label="1">金属探测仪模式</el-radio>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="curMenuStatus!==enums.menuStatus.planStatus" @click="taskDialogVisible = false">继续编辑
        </el-button>
        <el-button @click="uploadTask(enums.menuStatus.planStatus)" v-if="curMenuStatus===enums.menuStatus.taskStatus">
          另存为方案
        </el-button>
        <el-button type="primary" @click="beforeUpload(enums.menuStatus.taskStatus)">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="方 案 名 称"
        :visible.sync="planDialogVisible"
        width="25%">
      <el-input v-model="planData.planName" autocomplete="off" placeholder="请输入方案名称"
                clearable></el-input>
      <div style="margin-top: 10%">
        <label style="color:#096dd9;margin: 5px">土地类型：</label>
        <el-radio v-model="planData.landType" label="1">第一类土地类型</el-radio>
        <el-radio v-model="planData.landType" label="2">第二类土地类型</el-radio>
        <el-tooltip effect="dark" placement="top">
          <div slot="content">第一类用地：居住用地、公共管理和公共服务用地中的中小学用地、医疗卫生用地和社会福利设施用地，以及公园绿地中的社会公园或儿童公园用地。
            <br/>第二类用地：工业用地、物流仓储用地、商业服务业设施用地、道路与交通设施用地、公共设施用地、公共管理与公共服务用地，以及绿地与广场用地（除一类中公园绿地相关用地）
          </div>
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>

      </div>
      <div style="margin-top: 10%">
        <label style="color:#096dd9;margin: 5px">任务类型：</label>
        <el-radio v-model="planData.dataType" label="0">VOC模式</el-radio>
        <el-radio v-model="planData.dataType" label="1">金属探测仪模式</el-radio>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="planDialogVisible = false">继续编辑</el-button>
        <el-button type="primary" @click="postCarPosPlan">确 定</el-button>
      </div>
    </el-dialog>

    <Code ref="code"/>
  </div>


</template>

<script>
import TopHeader from "@/components/TopHeader";
import CenterMap from "@/components/CenterMap";
import request from "@/utils/request";
import Code from "@/components/Code";
import {enums} from '@/utils/common'
import RightTopChart from "@/components/RightTopChart";
import RightMiddleChart from "@/components/RightMiddleChart";
import RightBottomChart from "@/components/RightBottomChart";
import MyBtn from "@/components/MyBtn";
import CarSpeed from "@/components/CarSpeed";
import Humiture from "@/components/Humiture";
import CameraImage from "@/components/CameraImage";
import DynamicTable from "@/components/DynamicTable";
import Mileage from "@/components/Mileage";
import LayerCheckBox from "@/components/LayerCheckBox";
import Btn1 from "@/components/Btn1";
import Btn2 from "@/components/Btn2";
import Contaminants from "@/components/Contaminants";
import NewTask from "@/components/NewTask";
import TaskManage from "@/components/TaskManage";
import SchemeManage from "@/components/SchemeManage";
import coordtransform from 'coordtransform';
import axios from "axios";
import WindDirection from "@/components/WindDirection";
import AirData from "@/components/AirData";
import Voc from "@/components/Voc";

export default {
  name: "index",
  components: {
    Voc,
    AirData,
    WindDirection,

    TopHeader,
    CenterMap,
    Code,
    RightTopChart,
    RightMiddleChart,
    RightBottomChart,
    MyBtn,
    CarSpeed,
    CameraImage,
    DynamicTable,
    Mileage,
    LayerCheckBox,
    Btn1,
    Btn2,
    Contaminants,
    NewTask,
    TaskManage,
    SchemeManage,
    Humiture
  },
  data() {
    return {
      /**
       * @Description: 预定义data
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      enums: enums,
      userId: 3, // 用户ID
      tableData: [], // plan表格数据
      taskData: [
        // {
        // taskId: null,
        // taskName: "",
        // taskStatus: 1,
        // createUser: 3,
        // }
      ],//当前任务信息
      webSock: null, // ws实例
      taskDialogVisible: false, // 任务信息弹窗
      projectDialogVisible: false, // 巡检方案弹窗
      taskManageDialogVisible: false,// 任务管理弹窗
      planDialogVisible: false,//方案信息弹窗
      // 任务数据
      planData: {
        planId: null, //任务ID
        planName: '', //任务名称
        createUser: '', // 创建任务用户
        inspectionPointList: [], // 巡检任务路线
        dataType: null,
        landType: null,
        offsetId: null
      },
      //重金属测量数据
      metalData: {
        heavyMetalDetailId: null,
        pointId: null,
      },
      ocrTable: [], //记录显示在前端的识别结果数据
      //车辆数据
      carData: {
        carPosition: [],
        speed: 5, //无人车速度
        temperature: 0, //温度
        humidity: 0, //湿度
        mileage: 0, // 行驶里程
      },
      // 空气数据
      airData: {
        co2: -1,
        ch2o: -1,
        tvoc_air: -1,
        tvoc_soil: -1,
        pm25: -1,
        pm10: -1
      },
      gridOptions: [
        {
          gridGap: 10, label: '10米'
        },
        {
          gridGap: 20, label: '20米'
        }, {
          gridGap: 30, label: '30米'
        }
      ],
      gridGap: '',
      pollutionStandard: [],
      defaultPollution: null,
      coordOffset: {
        offsetId: null,
        lngOffset: 0,
        latOffset: 0
      },
      hisCoordOffset: {
        offsetId: null,
        lngOffset: 0,
        latOffset: 0
      },
      // 预定义voc污染物
      vocPollution: 'benzene',
      updateTaskId: null,
      isTaskRun: false, //当前是否存在运行中的任务

      curMenuStatus: enums.menuStatus.noStatus, //当前菜单模式
      curMode: enums.mode.noMode, //当前模式
      curCheckModeChild: enums.checkModeChild.noCheckMode,//当前编辑模式
      curRunMode: enums.runModeChild.noRunMode,
      // 预定义污染物类型
      pollutionMode: enums.pollutionMode.metalMode,
      // 获取小车实时位置用于创建方案所需变量
      isAddPoint: false,
      isCarPosPlanCreate: true,
      isCoordCorrect: true,

      newTaskMarkers: [], //保存绘制点
      rawMarkers: [], //原始点坐标集
      polyline: null, // 折线对象

      coordtransform: null,// 坐标转换对象
      // 监控图像相关设置
      baseUrl: "http://" + Code.ServerURL + "/uploadeview/",
      foreGroundMonitorUrl: '', //前部摄像头
      roadSurfaceMonitorUrl: '',//地面摄像头
      boardMonitorUrl: '', //地板摄像头
      XRayScreenMonitorUrl: '', //X射线荧光光谱仪屏幕摄像头
      // 插值图层预定义data
      myLabel: [],// 任务包含的污染物列表
      krigingData: {}, //元素监测地理数据
      pollutionWholeData: {},
      checkElementLayer: [],// 选中显示的插值图层
      isCanvas: false,
      isCanvasUpdate: false,
      showLayers: false,
      isGrid: false,
      wholeLabel: '总体评价',

      /**
       * @Description: 预定义data
       * @Auther: Tian
       * @Date: 2023/2/14
       */
      taskId: -1,//当前任务ID
      newtask: 0,
      taskProcess: 0,

      PointData: [],//节点信息，包括经纬度和完成情况
      AllLength: 0,//总距离
      FinishPoint: 0,//刚完成的点
      PassLength: 0,//已经走过的距离
      OriginPoint: [],//完成的上一个点
      DetectorType: 1,//任务类型，1：重金属，0：VOC
      TaskName: '无任务',//当前任务名称
      HeavyMetal: [],//重金属数据
      tempPD: [],

      ChangeTime: 0,
      activeTask: {},

      windData: {
        windDirection: 0,
        windDegree: 0
      }
    }
  },
  computed: {
    listenCheckModeChild: function () {
      return this.curCheckModeChild;
    },
    listenMode: function () {
      return this.curMode;
    },
    listenRunMode: function () {
      return this.curRunMode;
    },
    listenCheckElementLayer: function () {
      return this.checkElementLayer;
    },
    listenPollutionMode: function () {
      return this.pollutionMode;
    },


  },
  watch: {
    tempPD: {
      handler(newdata, olddata) {
        /**
         * @Description:
         * @Auther: Tian
         * @Date: 2023/2/14
         */
        this.PointData = [];
        for (let i = 0; i < newdata.length; i++) {
          this.PointData.push(newdata[i]);
        }
        // console.log(this.PointData);
      }
    },
    checkElementLayer: function (curCheckElementLayer) {
      /**
       * @Description: 监听选中的元素图层，判断已生成的图层是否含有该元素，没有则渲染，有则直接显示
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      // console.log('curCheckElementLayer', curCheckElementLayer)
      if (this.curMode === enums.mode.runMode && this.curRunMode === enums.runModeChild.runOffMode ||
          this.curMode !== enums.mode.runMode) {
        this.isCanvas = true;
        this.loadKriging(curCheckElementLayer);
      }
    },
    listenCheckModeChild: function (checkModeChild) {
      /**
       * @Description: 监听checkModeChild
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param checkModeChild:查看模式子模式
       */
      if (checkModeChild !== enums.checkModeChild.checkEditMode) {
        if (this.$refs.centerMap.polyEditor != null) {
          this.$refs.centerMap.polyEditor.close();
          this.$refs.centerMap.polyEditor = null;
        }
      }
      if (checkModeChild === enums.checkModeChild.checkCreateMode) {
        this.$refs.centerMap.mouseTool.marker({
          //同Marker的Option设置
        });
      } else {
        this.$refs.centerMap.mouseTool.close(false);
      }
    },
    listenRunMode: function (runMode) {
      if (this.curMode === enums.mode.runMode && runMode === enums.runModeChild.runOffMode) {

        // this.curMode=enums.mode.checkMode;
        // this.getTaskById2Kriging(this.taskId, this.hisCoordOffset);
        if (this.myLabel.indexOf(this.wholeLabel) === -1) this.myLabel.push(this.wholeLabel);
        this.checkElementLayer.push(this.wholeLabel);
      }
    },
    listenMode: function (mode) {
      /**
       * @Description: 监听mode,新建任务时启动mouseTool进行绘制，否则关闭绘制工具
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param mode: 模式标识符
       */
      if (mode === enums.mode.createMode) {
        this.$refs.centerMap.mouseTool.marker({
          //同Marker的Option设置
        });
      } else {
        this.$refs.centerMap.mouseTool.close(false);
      }
      if (mode === enums.mode.checkMode) {
        this.showLayers = true;
        if (this.myLabel.indexOf(this.wholeLabel) === -1) this.myLabel.push(this.wholeLabel);
      }
      if (mode === enums.mode.runMode) {
        this.showLayers = true;
        this.isGrid = true;
      }
      if (mode === enums.mode.noMode || mode === enums.mode.correctCoordMode) {
        this.isGrid = false;
        this.showLayers = false;
        this.isCanvas = false;
      }
    },
    listenPollutionMode: function (pollutionMode) {
      /**
       * @Description: 判断监测污染物类型，刷新组件，更换界面样式
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param pollutionMode:污染物类型
       */
      if (pollutionMode === enums.pollutionMode.metalMode) {
        this.$message("重金属模式")
      } else if (pollutionMode === enums.pollutionMode.vocMode) {
        this.$message("VOC模式")
      }
    }
  },
  mounted() {
    /**
     * @Description: 页面初始化结束后加载内容
     * @Auther: HLY
     * @Date: 2023/2/14
     */
    this.getPlans(); //更新方案表格
    this.getTasks(); //更新任务表格
    this.initWebSocket("ws://" + Code.ServerURL + "/api/getWSConnection?userType=adminUser&userId=" + this.userId); //初始化webSocket
    this.getActivateTask(true);
    this.renew();
    this.getOffset();
    this.getPollutionStandard();
    // this.$refs.centerMap.drawGrid(10, 2, this.hisCoordOffset);
    // this.getTaskById2Kriging(1);

    this.$watch(
        /**
         * @Description: 判断是否为新增点
         * @Auther: HLY
         * @Date: 2023/2/14
         */
        () => {
          if (this.polyline) {
            return [this.newTaskMarkers, this.rawMarkers, this.polyline.getPath()]
          } else {
            return [this.newTaskMarkers, this.rawMarkers, this.polyline]
          }
        },
        (val) => {
          this.isAddPoint = val[0].length - val[1].length > 0;
          if (val[2]) {
            this.isAddPoint = this.isAddPoint || (val[2].length - val[1].length > 0)
          }
        }
    );
    // 加载坐标转换工具
    this.coordtransform = require('coordtransform');
  },
  destroyed() {
    // 页面关闭时关闭websocket
    this.webSock.close();
  },
  methods: {
    getBuildingData() {
      /**
       * @Description:
       * @Auther: Tian
       * @Date: 2023/2/14
       */
      let inputObj = document.createElement('input')
      inputObj.setAttribute('id', 'my-inputObj');
      inputObj.setAttribute('type', 'file');
      inputObj.setAttribute("style", 'visibility:hidden');
      document.body.appendChild(inputObj);


      inputObj.addEventListener('change', () => {
        const reader = new FileReader()
        reader.readAsText(inputObj.files[0], 'utf8') // input.files[0]为第一个文件
        reader.onload = () => {
          var buildingobj = JSON.parse(reader.result);
          console.log(buildingobj);
          this.$refs.centerMap.buildingLayers(buildingobj);

        }
      }, false)
      inputObj.click();
    },
    async renew() {
      /**
       * @Description:刷新左侧上半部分
       * @Auther: Tian
       * @Date: 2023/2/14
       */
      this.taskId = -1;
      this.TaskName = "无任务";
      this.taskProcess = 0;

      let waiting = await this.getActivateTask();
      if (waiting === true) this.updatePointList();

    },


    updatePointList: function () {
      /**
       * @Description:更新节点列表
       * @Auther: Tian
       * @Date: 2023/2/14
       */
      this.PointData = [];
      this.AllLength = 0;
      this.PassLength = 0;
      if (this.taskId === -1) {
      // if (this.curMode!==enums.mode.runMode){
        console.log("无正在执行的任务");
        return;
      }

      request.get('/tasks/' + this.taskId).then(res => {
        // console.log(res);
        this.lastpoint = res.data.detectionPointList[0];
        for (var i = 0; i < res.data.detectionPointList.length; i++) {
          this.AllLength += this.Distance(res.data.detectionPointList[i].longitude, res.data.detectionPointList[i].latitude, this.lastpoint.longitude, this.lastpoint.latitude);
          if (i < this.activeTask.taskProcess) {
            this.PointData.push([res.data.detectionPointList[i].longitude, res.data.detectionPointList[i].latitude, "已完成"]);
          } else if (i === this.activeTask.taskProcess) this.PointData.push([res.data.detectionPointList[i].longitude, res.data.detectionPointList[i].latitude, "正在前往"]);
          else this.PointData.push([res.data.detectionPointList[i].longitude, res.data.detectionPointList[i].latitude, "未完成"]);
          this.lastpoint = res.data.detectionPointList[i];
        }
        // this.taskProcess = Math.round(this.PassLength / this.AllLength * 100);
        if (this.PointData.length === 0) this.taskProcess = 0;
        else {
          this.taskProcess = Math.round(this.activeTask.taskProcess / this.PointData.length * 100);
          if (this.activeTask.taskProcess === this.PointData.length) {
            this.taskProcess = 100;
            // this.taskId = -1;
            this.TaskName = "任务：" + this.TaskName + "已完成";
            this.curRunMode = enums.runModeChild.runOffMode;

          }
        }
      })
    },
    getActivateTask: function (loadCurTask = false) {
      /**
       * @Description: 获取当前运行的任务
       * @Auther: Tian
       * @Date: 2023/2/14
       */
      request.get('/tasks/activeTasks').then(res => {
        if (res.data != null) {
          var key = Object.keys(res.data);
          this.activeTask = {
            taskId: res.data[key[0]].taskId,
            dataType: res.data[key[0]].taskId.dataType,
            taskName: res.data[key[0]].taskName,
            taskProcess: res.data[key[0]].taskProcess
          }
          this.taskId = this.activeTask.taskId;
          this.TaskName = this.activeTask.taskName;
          if (loadCurTask) {
            // 地图加载数据
            request.get('/tasks/' + res.data[key[0]].taskId).then(res => {
              let data = res.data;
              this.handleClick(data.detectionPointList, data.taskId, data.taskName,
                  data.dataType, data.landType, data.offsetId, data.detectionDataList, enums.mode.noMode);
              // this.hisCoordOffset = data.coordOffset;
              // this.curMode = enums.mode.noMode;
              this.curMode = enums.mode.runMode;
              this.curRunMode = enums.runModeChild.runOnMode;
            })
          }

        }

      })
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      });
    },
    ChangeHeavyMetal(item) {
      /**
       * @Description:
       * @Auther: Tian
       * @Date: 2023/2/14
       */
      this.HeavyMetal = item;
      console.log(this.HeavyMetal);
    },
    Distance(lng1, lat1, lng2, lat2) {
      /**
       * @Description:计算距离
       * @Auther: Tian
       * @Date: 2023/2/14
       */
      lat1 = lat1 || 0;
      lng1 = lng1 || 0;
      lat2 = lat2 || 0;
      lng2 = lng2 || 0;
      let rad1 = lat1 * Math.PI / 180.0;
      let rad2 = lat2 * Math.PI / 180.0;
      let a = rad1 - rad2;
      let b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
      let r = 6378137;
      return r * 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(rad1) * Math.cos(rad2) * Math.pow(Math.sin(b / 2), 2)));
    },

    CreateNewTask: async function () {
      /**
       * @Description:创建新任务
       * @Auther: Tian
       * @Date: 2023/2/14
       */
      await this.initAll();//存在异步问题，等全部初始化后在执行后续操作

      if (this.taskId > 0) {
        console.log(this.taskId);
      // if (this.curMode===enums.mode.runMode&& this.curRunMode ===enums.runModeChild.runOnMode){
        let message = "已有任务正在执行，是否停止？";
        this.$confirm(message, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 取消执行
          let output = "已取消执行";
          console.log(this.taskId);
          request.put("/tasks/stop/" + this.taskId).then(res => {
            output = res['reason'];
            console.log(output);
            this.renew();
            this.clearMapAll();
          })

          this.$message({
            type: 'success',
            message: output
          });
        })
      } else {
        this.initCanvasLayer();
        this.clearMapAll();
        this.drawNewTaskMarker();
        this.curMenuStatus = enums.menuStatus.taskStatus;
        this.curMode = enums.mode.createMode;
        // console.log(this.curMode)

      }
    },
    TaskManageBottom() {
      /**
       * @Description:
       * @Auther: Tian
       * @Date: 2023/2/14
       */
      this.getTasks();
      this.taskManageDialogVisible = true;
      this.curMenuStatus = enums.menuStatus.taskStatus;
    },
    SchemeManageBottom() {
      /**
       * @Description:
       * @Auther: Tian
       * @Date: 2023/2/14
       */
      this.projectDialogVisible = true;
      this.curMenuStatus = enums.menuStatus.planStatus;
    },
    initAll() {
      /**
       * @Description: 初始化状态与变量
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      this.curMenuStatus = enums.menuStatus.noStatus; //当前菜单模式
      this.curMode = enums.mode.noMode; //当前模式
      this.curCheckModeChild = enums.checkModeChild.noCheckMode;//当前编辑模式
      this.$refs.centerMap.clearDraw(true, true, true);
      this.$refs.centerMap.newTaskMarkers = [];
      this.$refs.centerMap.rawMarkers = [];
      this.planData = {
        planId: null, //任务ID
        planName: '', //任务名称
        createUser: '', // 创建任务用户
        inspectionPointList: [], // 巡检任务路线
        dataType: null,
        landType: null,
        offsetId: null
      };
      // this.pollutionMode = this.activeTask.dataType === 1 ? enums.pollutionMode.metalMode : enums.pollutionMode.vocMode
    },
    cancelEdit() {
      /**
       * @Description: 取消按钮响应函数，清除地图绘制内容并还原标识变量
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      if (this.curMode === enums.mode.createMode) {
        this.$refs.centerMap.clearDraw(true, true, true);
        this.curMode = enums.mode.noMode;
      } else if (this.curMode === enums.mode.checkMode) {
        this.clearMapAll();
        this.getActivateTask(true);
        if (this.curMode === enums.mode.checkMode) this.curMode = enums.mode.noMode;
        this.curCheckModeChild = enums.checkModeChild.noCheckMode;
      } else if (this.curMode === enums.mode.correctCoordMode) {
        this.clearMapAll();
        this.getOffset();
        this.isCoordCorrect = true;
        this.curMode = enums.mode.noMode;
      }
    },

    uploadTask: function (taskType) {
      /**
       * @Description: 1、判断运行为任务或方案，并执行任务。关闭相关弹窗 2、
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      // 需要根据用户输入进行修改
      if (this.planData.planName === '') {
        this.$alert('当前任务名称为空，请命名任务名称', '', {type: "warning"});//  警告
      } else if (this.planData.dataType === null) {
        this.$alert('当前任务类型为空，请选择任务类型', '', {type: "warning"});//  警告
      } else if (this.planData.landType === null) {
        this.$alert('当前土地类型为空，请选择土地类型', '', {type: "warning"});//  警告
      } else {
        // this.clearMapAll();
        if (taskType === enums.menuStatus.taskStatus) {
          this.sendTask2Run();
          if (this.curMode !== enums.mode.createMode) {
            this.clearMapAll();
            this.drawHisTask(this.planData.inspectionPointList);
            // this.getActivateTask(true);
          }
        } else if (taskType === enums.menuStatus.planStatus) {
          this.sendPlan();
          this.taskDialogVisible = false;
          this.projectDialogVisible = false;
          this.curMode = enums.mode.noMode;
          this.curCheckModeChild = enums.checkModeChild.noCheckMode;
        }

      }
    },
    sendTask2Run: function () {
      /**
       * @Description: 提交task并发送到智能车执行task
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      // console.log('polyline',this.polyline)
      if (this.isTaskRun) {
        this.$alert('存在正在执行的任务，请在任务完成后执行新任务', '', {type: "warning"});
      }
      if (!this.polyline) {
        if (this.curMode === enums.mode.createMode) {
          this.$alert('当前任务监测点为空，请编辑任务', '', {type: "warning"});
        } else {
          this.runTask(this.userId, this.planData.planName, this.planData.inspectionPointList, this.planData.dataType, this.planData.landType);
        }
      } else {
        let detectionPointList = [];
        for (let obj of this.polyline.getPath()) {
          let pos = this.coordtransform.gcj02towgs84(obj.lng, obj.lat);
          // console.log('task pos before correct:', pos)
          detectionPointList.push({
            longitude: pos[0] - this.coordOffset.lngOffset,
            latitude: pos[1] - this.coordOffset.latOffset
          })
        }
        // console.log('task pos after correct:', detectionPointList)
        // console.log('detectPointlst',detectionPointList)
        this.runTask(this.userId, this.planData.planName, detectionPointList, this.planData.dataType, this.planData.landType);
      }
      this.taskDialogVisible = false;
      this.projectDialogVisible = false;
    },
    postTask: function (taskData) {
      /**
       * @Description: 上传检测任务到数据库
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      // console.log(taskData)
      request.post('/tasks', JSON.stringify(taskData)).then(res => {
        //  判断上传成功
        let message = '成功提交任务，开始执行任务！';
        if (this.curMenuStatus === enums.menuStatus.planStatus) {
          message = '成功提交，开始执行方案！'
        }
        this.$message({
          type: 'success',
          message: message
        });
        console.log(res);
        if (res.data != null) {
          this.taskId = res.data.taskId;
          this.detectPointIndex = 0;
          console.log(this.taskId);
          this.curMode = enums.mode.runMode;
          this.curRunMode = enums.runModeChild.runOnMode;
        }

        // this.curMode = enums.mode.noMode;

        // 获取当前运行任务信息
        // this.setCurTask(null, planName, 5, taskData.detectionPointList);
        // 当前是否有正在运行的任务
        // this.isTaskRun = true;
        // 运行任务后，清除地图中上次任务的数据监测点的点标记，数据也删除
        // this.$refs.centerMap.clearMonitorPoints();
        // this.planData.planName = '';
      })
    },
    sendPlan: function () {
      /*
      上传任务并询问用户是否执行该任务
      isNewTask：是否为新任务，是则直接提交，否则更新该任务
       */
      if (!this.polyline && this.curMode === enums.mode.createMode) {
        this.$alert('当前任务监测点为空，请编辑任务', '', {type: "warning"});
      } else {
        this.planData.createUser = this.userId;
        this.planData.planId = null;
        this.planData.offsetId = this.coordOffset.offsetId;
        for (let obj of this.polyline.getPath()) {
          let pos = this.coordtransform.gcj02towgs84(obj.lng, obj.lat);
          this.planData.inspectionPointList.push({
            longitude: pos[0] + this.coordOffset.lngOffset,
            latitude: pos[1] + this.coordOffset.latOffset
          })
        }
        // if (isNewPlan) {
        // console.log(this.planData)
        request.post('/plans', JSON.stringify(this.planData)).then(res => {
          // console.log(res)
          this.$message({
            type: 'success',
            message: '已成功提交'
          });
          this.getPlans();
        })
      }
      this.taskDialogVisible = false;

    },
    deletePlan: function (planId) {
      /**
       * @Description: 通过任务ID删除任务，并更新表格
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      request.delete('/plans/' + planId).then(res => {
        this.$refs.centerMap.clearDraw(true, true, true);
        this.getPlans();
      })
    },
    getPlans: function () {
      /**
       * @Description:获取所有巡检方案，更新表格
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      request.get('/plans').then(res => {
        console.log('plan-data', res.data)
        this.tableData = res.data;
      })
    },
    createCarPlan: function () {
      /**
       * @Description: 控制小车轨迹创建方案相关控件显示
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      if (this.isCarPosPlanCreate) {
        this.planData.inspectionPointList = [];
        this.isCarPosPlanCreate = false;
      } else {
        this.planDialogVisible = true;
      }
    },
    addCarPos2Plan: function () {
      /**
       * @Description: 添加小车当前位置到小车轨迹列表中
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      if (this.carData.carPosition.length !== 0) {
        this.planData.inspectionPointList.push({
          longitude: this.carData.carPosition[0],
          latitude: this.carData.carPosition[1]
        })
        // console.log(this.planData.inspectionPointList)
      } else {
        this.$message({
          type: 'error',
          message: '获取当前坐标失败'
        })
      }
    },
    postCarPosPlan: function () {
      /**
       * @Description: 提交基于小车轨迹创建的方案
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      console.log(111);
      request.post('/CoordCorrection?lngOffset=' + 0 +
          '&latOffset=' + 0).then(res => {
        this.planData.planId = null;
        this.planData.createUser = this.userId;
        this.planData.offsetId = res.data.offsetId;
        request.post('/plans', JSON.stringify(this.planData)).then(res => {
          // console.log(res)
          this.planDialogVisible = false;
          this.isCarPosPlanCreate = true;
          //  刷新方案管理弹窗
          this.getPlans();
        })
      })
    },
    getOffset: function () {
      /**
       * @Description: 获取数据库中最新的坐标偏移量
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      request.get('/CoordCorrection/recentOffset/').then(res => {
        this.coordOffset.offsetId = res.data.offsetId;
        this.coordOffset.lngOffset = res.data.lngOffset;
        this.coordOffset.latOffset = res.data.latOffset;
      })
    },
    coordCorrect: function () {
      /**
       * @Description:坐标校正，两部分【绘制定标点、提交偏移量】
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      if (this.isCoordCorrect) {
        /*
        绘制定标点
         */
        this.$refs.centerMap.pointMouseTool.marker({
          //同Marker的Option设置
        });
        this.curMode = this.enums.mode.correctCoordMode;
      } else {
        /*
        计算并提交偏移量
         */
        // 提交偏移量到数据库
        request.post('/CoordCorrection?lngOffset=' + this.coordOffset.lngOffset +
            '&latOffset=' + this.coordOffset.latOffset).then(res => {
          this.coordOffset.offsetId = res.data.offsetId;
          this.$refs.centerMap.clearCarMarker();
          this.$refs.centerMap.showCarPosition([this.carData.carPosition[0] + this.coordOffset.lngOffset,
            this.carData.carPosition[1] + this.coordOffset.latOffset]);
          this.clearMapAll();
          this.curMode = enums.mode.noMode;
        })
      }
      this.isCoordCorrect = !this.isCoordCorrect;
    },
    calcOffset: function () {
      /**
       * @Description: 计算高德地图定标点（坐标转为WGS84后）与小车位置的偏移量
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      if (!this.$refs.centerMap.pointlayer) {
        this.$message({
          type: 'warning',
          message: '请绘制定标点！'
        });
      } else if (this.carData.carPosition.length === 0) {
        this.$message({
          type: 'warning',
          message: '未获取到智能车位置，请连接智能车！'
        });
      } else {
        // 定标点marker坐标
        let position = this.$refs.centerMap.pointlayer._position;
        let pos = this.coordtransform.gcj02towgs84(position.lng, position.lat);
        this.coordOffset.lngOffset = pos[0] - this.carData.carPosition[0];
        this.coordOffset.latOffset = pos[1] - this.carData.carPosition[1];
      }
    },
    getOffsetById: function (id) {
      /**
       * @Description: 基于任务ID获取其当时的校正数据
       * @Auther: HLY
       * @Date: 2023/2/15
       */
      request.get('/CoordCorrection/' + id).then(res => {
        this.hisCoordOffset.offsetId = res.data.offsetId;
        this.hisCoordOffset.lngOffset = res.data.lngOffset;
        this.hisCoordOffset.latOffset = res.data.latOffset;
      })
    },
    offset2Map: function () {

    },
    drawNewTaskMarker: function () {
      /*
      绘制点标记，先清除搜索产生的点标记，若已存在绘制的点，则弹出重绘或继续绘制的弹窗
       */
      this.$message({
        type: 'info',
        message: '开始创建监测点'
      });
    },
    editPolyline: function () {
      /**
       * @Description:启动巡检路线编辑功能
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      this.curCheckModeChild = enums.checkModeChild.checkEditMode;
      this.$refs.centerMap.startPolylineEdit();
    },
    reloadTask: function (inspectionPointList) {
      /**
       * @Description:绘制当前正在运行的任务
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param inspectionPointList:当前正在运行的任务监测点列表
       */
          // this.planData.planId = planId;
      let points = [];
      inspectionPointList.forEach(item => {
        points.push([item[0], item[1]])
      });
      this.$refs.centerMap.newTaskMarkers = points;
      this.$refs.centerMap.drawPoints(points);
      this.$refs.centerMap.drawPolyline(points);
    },
    drawHisTask: function (inspectionPointList) {
      /**
       * @Description: 绘制历史巡检方案及监测点
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param inspectionPointList:历史任务监测点列表
       * @param planId:任务ID
       */
      let points = [];
      let sPoints = [];
      // console.log('i', inspectionPointList)
      for (let item of inspectionPointList) {
        // console.log('history task pos before correct:', item.longitude, item.latitude)
        // console.log('history task pos after correct:', item.longitude + this.hisCoordOffset.lngOffset, item.latitude + this.hisCoordOffset.latOffset)
        // 基于历史偏移量进行坐标校正
        let gcj02_position = this.coordtransform.wgs84togcj02(item.longitude + this.hisCoordOffset.lngOffset
            , item.latitude + this.hisCoordOffset.latOffset);//将WGS84转为GCJ-02
        points.push([gcj02_position[0], gcj02_position[1]])
        sPoints.push([gcj02_position[0], gcj02_position[1]])
      }
      this.$refs.centerMap.rawMarkers = sPoints;
      this.$refs.centerMap.newTaskMarkers = points;
      this.$refs.centerMap.drawPoints(points);
      this.$refs.centerMap.drawPolyline(points, true);
      // this.$refs.centerMap.map.setFitView();
    },
    handleClick: function (inspectionPointList, planId, planName, dataType, landType, offsetId, detectionDataList = null, curMode = enums.mode.checkMode) {
      /**
       * @Description: 处理表格中任务的“查看”点击事件,绘制任务点、监测数据点、处理监测数据为插值数据
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param inspectionPointList:当前任务训练路线
       * @param planId：任务ID
       * @param planName:任务名称
       * @param dataType:监测任务类型，0：voc，1：重金属
       * @param detectionDataList:监测数据点列表
       */
      this.clearMapAll();
      if (offsetId) this.getOffsetById(offsetId);
      // .then(val => {
      // console.log(this.hisCoordOffset)
      if (this.curMenuStatus === enums.menuStatus.planStatus) {
        this.projectDialogVisible = false;
      }
      if (this.curMenuStatus === enums.menuStatus.taskStatus) {
        this.taskManageDialogVisible = false;
        if (detectionDataList !== null && detectionDataList.length !== 0) {
          // console.log(detectionDataList)
          for (let monitorData of detectionDataList) {
            this.drawMonitor(monitorData, this.hisCoordOffset);
          }
          // console.log(detectionDataList)
          this.isGrid = false;
          this.initCanvasLayer();
          this.getTaskById2Kriging(planId, this.hisCoordOffset);
          this.isCanvasUpdate = true;
        }
      }
      this.curMode = curMode;
      this.planData.planId = planId;
      // console.log('inspectionPointList',inspectionPointList)
      this.drawHisTask(inspectionPointList);
      // 获取当前方案信息，用于之后的提交
      this.planData.planName = planName;
      this.planData.dataType = dataType;
      this.planData.landType = landType;
      this.pollutionMode = dataType === 1 ? enums.pollutionMode.metalMode : enums.pollutionMode.vocMode;
      this.planData.inspectionPointList = inspectionPointList;
      // console.log(this.planData)

      /**
       * @author tian
       */
      // this.taskId = planId;
      // this.TaskName = planName;
      // this.updatePointList();
      // });
      // this.renew();


    },

    beforeRun: function (planId, planName, inspectionPointList, dataType, landType) {
      if (this.taskId > 0) {
        console.log(this.taskId);
      // if (this.curMode===enums.mode.runMode&& this.curRunMode ===enums.runModeChild.runOnMode){
        let message = "已有任务正在执行，是否停止？";
        this.$confirm(message, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 取消执行
          let output = "已取消执行";
          console.log(this.taskId);
          request.put("/tasks/stop/" + this.taskId).then(res => {
            output = res['reason'];
            console.log(output);
            this.curMode = enums.mode.noMode;
            this.renew();
          })

          this.$message({
            type: 'success',
            message: output
          });
        })
      } else this.handleRun(planId, planName, inspectionPointList, dataType, landType);
    },

    handleRun: function (planId, planName, inspectionPointList, dataType, landType) {
      /*
      运行巡检方案
       */
      this.drawHisTask(inspectionPointList);
      let detectionPointList = [];
      // if (this.curMenuStatus === enums.menuStatus.planStatus){
      inspectionPointList.forEach(obj => {
        detectionPointList.push({
          longitude: obj.longitude,
          latitude: obj.latitude
        })
      });

      // }
      this.runTask(this.userId, planName, detectionPointList, dataType, landType);
      if (this.curMenuStatus === enums.menuStatus.planStatus) {
        this.projectDialogVisible = false;
      }
      if (this.curMenuStatus === enums.menuStatus.taskStatus) {
        this.taskManageDialogVisible = false;
      }
    },
    coordTrans: function (pointList, isWgs842Gcj02) {
      /**
       * @Description:将pointlist中坐标进行转换，
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param pointList:坐标对象[obj，obj]
       * @param isWgs842Gcj02:是否为WGS84转为GCJ02坐标
       */
          // console.log(pointList)
      let detectionPointList = [];
      if (!pointList || pointList.length === 0) {
        return detectionPointList;
      }
      pointList.forEach(obj => {
        let position = [];
        if (isWgs842Gcj02) {
          position = this.coordtransform.wgs84togcj02(obj.longitude, obj.latitude);
        } else {
          position = this.coordtransform.gcj02towgs84(obj.longitude, obj.latitude);
        }
        detectionPointList.push({
          longitude: position[0],
          latitude: position[1]
        })
      })
      return detectionPointList;
    },
    runTask: function (userId, taskName, pointList, dataType, landType, updateTaskId = null) {
      /**
       * @Description: 1、基于传入参数，写入任务数据，并提交任务 2、
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      let taskData = {
        createUser: userId,
        detectionPointList: [],
        taskName: taskName,
        dataType: Number(dataType),
        landType: Number(landType),
        offsetId: this.coordOffset.offsetId
      };
      if (this.curMenuStatus === enums.menuStatus.taskStatus) {
        taskData.updateTaskId = updateTaskId;
      }
      taskData.detectionPointList = pointList;
      this.postTask(taskData);
      //修改当前污染物模式
      this.pollutionMode = taskData.dataType === 1 ? enums.pollutionMode.metalMode : enums.pollutionMode.vocMode;
      this.hisCoordOffset = this.coordOffset;

      // 作用是什么？
      // if (pointList[0]['longitude'] !== pointList[0]['longitude']) {
      //   for (let i = 0; i < this.newTaskMarkers.length; i++) {
      //     pointList[i]['longitude'] = this.newTaskMarkers[i][0];
      //     pointList[i]['latitude'] = this.newTaskMarkers[i][1];
      //   }
      // }
      /**
       * @Description:
       * @Auther: Tian
       * @Date: 2023/2/14
       */

      setTimeout(() => {
        this.renew();
      }, 1000);

    },
    drawMonitor: function (detectionDataList, hisOffset) {
      /**
       * @Description:绘制小车报告的监测点
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param detectionDataList:监测点数据列表
       * @param hisOffset:历史坐标偏移量
       */
      this.$refs.centerMap.drawMonitorPoint(detectionDataList, hisOffset);
    },
    clearMapAll: function () {
      /**
       * @Description:  清除地图覆盖物，并将本地存储列表清空
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      this.$refs.centerMap.clearDraw(true, true, true);
      this.$refs.centerMap.newTaskMarkers = [];
      this.$refs.centerMap.rawMarkers = [];
      this.$refs.centerMap.clearMonitorPoints();
      this.initCanvasLayer();
    },
    getTasks: function () {
      /**
       * @Description: 获取所有检测任务详情
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      request.get('/tasks').then(res => {
        // console.log('task', res.data)
        this.taskData = res.data;
      })
    },
    filterTag: function (value, row) {
      /**
       * @Description: 表格过滤标签
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      return row.taskStatus === value;
    },
    formatterCreateUser: function (row, column) {
      /**
       * @Description: 用户类型格式化
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      switch (row.createUser) {
        case 1:
          return "浏览器";
        case 2:
          return "机器人";
        case 3:
          return "操作员";
        case 4:
          return "管理员";
      }
    },
    initCanvasLayer: function () {
      /**
       * @Description: 初始化canvas图层相关数据及地图图层
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      this.krigingData = {};
      this.myLabel = [];
      this.checkElementLayer = [];
      this.isCanvasUpdate = false;
      this.showLayers = false;
      this.$refs.centerMap.removeCanvasLayers();
      this.$refs.centerMap.canvasLayers = {};
    },
    canvasLayerSwitch: function (lngOffsetRatio = 1, latOffsetRation = 1, defaultElement = null) {
      /**
       * @Description: 切换canvas图层显示隐藏
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      if (this.isCanvasUpdate) {
        // 初始化图层显示data
        this.initCanvasLayer();
      }
      // this.getOffsetById(this.hisCoordOffset.offsetId);
      this.getTaskById2Kriging(this.planData.planId, this.hisCoordOffset);
      this.isCanvasUpdate = true;
    },
    loadKriging: function (curCheckElementLayer) {
      /**
       * @Description: 处理插值图层选择
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param curCheckElementLayer:当前选中元素图层
       */
          //     console.log('load kriging curcheck element layer:', curCheckElementLayer);
          // console.log('curMode:',this.curMode,'curRunMode:',this.curRunMode)
          // console.log('canvas layers:', this.$refs.centerMap.canvasLayers)
      let canvasLayers = Object.keys(this.$refs.centerMap.canvasLayers);
      for (let element of curCheckElementLayer) {
        if (this.$refs.centerMap.canvasLayers.hasOwnProperty(element)) {
          if (!this.$refs.centerMap.canvasLayers[element].getOptions().visible) {
            // console.log('show')
            this.$refs.centerMap.canvasLayers[element].show();
          }
        } else {
          // request.get('/pollutantStandard/' + element).then(res => {
          // 判断当前任务的土地类型
          console.log('element:', element, 'standard:', this.pollutionStandard);

          if (element === this.wholeLabel) {
            this.getWholePollutionByTaskId(this.planData.planId, element, null);
          } else {
            let standard = Number(this.planData.landType) === 1 ? [
                this.pollutionStandard[element].firstClassLandFiltStandard,
                  this.pollutionStandard[element].firstClassLandControlStandard] :
                [this.pollutionStandard[element].secondClassLandFiltStandard,
                  this.pollutionStandard[element].secondClassLandControlStandard];
            this.$refs.centerMap.loadKriging(element, this.krigingData[element], standard, false);
          }
        }
        // 移除选中的元素，用于后续隐藏未选中的元素
        let index = canvasLayers.indexOf(element);
        if (index !== -1) canvasLayers.splice(index, 1);
      }
      // console.log('canvas layers:', this.$refs.centerMap.canvasLayers)
      //隐藏未选中的元素
      for (let element of canvasLayers) {
        this.$refs.centerMap.canvasLayers[element].hide();
      }
    },
    checkChange: function (index, label) {
      // console.log('change label:', label,'index:',index);
      // console.log('element layer:', this.checkElementLayer);
      // if (this.checkElementLayer.indexOf(label)!==-1){
      //   this.checkElementLayer.push(label);
      // }else{
      //
      // }

    },
    // addCheckedElementLayer: function (label) {
    //   /**
    //    * @Description: 添加选中的元素到元素图层列表
    //    * @Auther: HLY
    //    * @Date: 2023/2/14
    //    */
    //   if (label !== null && !this.checkElementLayer.includes(label)) {
    //     // console.log('add check element layer:', label);
    //     this.checkElementLayer.push(label);
    //     // this.getPollutionStandard(label)
    //     // this.loadKriging(this.checkElementLayer);
    //   }
    // },
    // removeUnCheckedElementLayer: function (label) {
    //   /**
    //    * @Description: 添加选中的元素
    //    * @Auther: HLY
    //    * @Date: 2023/2/14
    //    */
    //   this.checkElementLayer.forEach(function (item, index, arr) {
    //     if (item === label) {
    //       arr.splice(index, 1);
    //     }
    //   });
    //   // this.loadKriging(this.checkElementLayer);
    // },
    getWholePollutionByTaskId: function (taskId, element, standard) {
      /**
       * @Description: 获取标准化处理后的总体概览插值数据
       * @Auther: HLY
       * @Date: 2023/2/19
       */
      let lngInterpOffset = 0.001141 * 0.5;
      let latInterpOffset = 0.000899 * 0.1;
      request.get('/dataAnalysis/getWholePollutionByTaskId/' + taskId).then(res => {
        this.pollutionWholeData['data'] = [];
        for (let data of res.data.detectionDataList) {
          let pos = this.coordtransform.wgs84togcj02(data.longitude + this.hisCoordOffset.lngOffset,
              data.latitude + this.hisCoordOffset.latOffset);
          this.pollutionWholeData['data'].push({
            "value": data.finalValue, "latitude": pos[1], "longitude": pos[0]
          });
        }

        console.log('data', this.pollutionWholeData['data'])
        // 获取每个元素检测值的坐标范围
        let pos = [];
        this.pollutionWholeData['data'].forEach(function (element) {
          pos.push([element.longitude, element.latitude]);
        })
        let miny = Math.min(...pos.map((v) => v[1]));
        let maxy = Math.max(...pos.map((v) => v[1]));
        let minx = Math.min(...pos.map((v) => v[0]));
        let maxx = Math.max(...pos.map((v) => v[0]));
        console.log(miny, maxy, minx, maxx)
        // console.log(lngInterpOffset, latInterpOffset)
        this.pollutionWholeData["xlim"] = [minx - lngInterpOffset, maxx + lngInterpOffset];
        this.pollutionWholeData["ylim"] = [miny - latInterpOffset, maxy + latInterpOffset];
        this.$refs.centerMap.loadKriging(element, this.pollutionWholeData, standard, true);
      })
      // return defaultElement;
    },
    getTaskById2Kriging: async function (taskId, hisOffset, lngOffsetRatio = 0.5, latOffsetRatio = 0.1, defaultElement = null) {
      /**
       * @Description:获取任务监测点数据，并解析为元素：{检测值，经度，纬度}形式，用于克里金插值
       上下左右各扩大100米范围
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param taskId: 任务ID
       * @param hisOffset: 坐标校正偏移量
       * @param lngOffsetRatio: 经度插值偏移量,0.001141为100米，输入比例值作为最终偏移量
       * @param latOffsetRatio: 纬度插值偏移量,0.000899为100米，输入比例值作为最终偏移量
       */
      let lngInterpOffset = 0.001141 * lngOffsetRatio;
      let latInterpOffset = 0.000899 * latOffsetRatio;

      // let krigingData = {};
      // let myLabel = [];
      await request.get('/tasks/' + taskId).then(res => {
        // console.log(1, res.data)
        // 解析taskId任务的监测数据为 元素：{data：{value，latitude，longitude},xlim:[xmin，xmax],ylim:[ymin，ymax]}
        for (let data of res.data.detectionDataList) {
          let pollutionDetail = data.heavyMetalDetail ? data.heavyMetalDetail : data.vocDetail;
          // console.log(pollutionDetail)
          for (let key in pollutionDetail) {
            if (data.heavyMetalDetail) {
              //重金属数据
              if (key !== "heavyMetalDetailId" && key !== "pointId") {
                if (pollutionDetail[key] === null) continue;
                let pos = this.coordtransform.wgs84togcj02(data.longitude + hisOffset.lngOffset,
                    data.latitude + hisOffset.latOffset);
                if (!this.krigingData.hasOwnProperty(key)) {
                  this.krigingData[key] = {
                    "data": [{
                      "value": pollutionDetail[key], "latitude": pos[1], "longitude": pos[0]
                    }]
                  };
                  this.myLabel.push(key);
                } else {
                  this.krigingData[key]['data'].push({
                    "value": pollutionDetail[key], "latitude": pos[1], "longitude": pos[0]
                  })
                }
              }
            } else {
              //Voc数据
              if (key !== "vocDetailId" && key !== "pointId" && key !== 'windDirection' &&
                  key !== 'windDegree' && key !== 'temperature' && key !== 'humidity'
              ) {
                if (pollutionDetail[key] === null) continue;
                let pos = this.coordtransform.wgs84togcj02(data.longitude + hisOffset.lngOffset,
                    data.latitude + hisOffset.latOffset);
                if (!this.krigingData.hasOwnProperty(key)) {
                  this.krigingData[key] = {
                    "data": [{
                      "value": pollutionDetail[key], "latitude": pos[1], "longitude": pos[0]
                    }]
                  };
                  this.myLabel.push(key);
                } else {
                  this.krigingData[key]['data'].push({
                    "value": pollutionDetail[key], "latitude": pos[1], "longitude": pos[0]
                  });
                }
              }
            }
          }
        }
        // 获取每个元素检测值的坐标范围
        for (let key in this.krigingData) {
          let pos = [];
          this.krigingData[key]['data'].forEach(function (element) {
            pos.push([element.longitude, element.latitude]);
          })
          let miny = Math.min(...pos.map((v) => v[1]));
          let maxy = Math.max(...pos.map((v) => v[1]));
          let minx = Math.min(...pos.map((v) => v[0]));
          let maxx = Math.max(...pos.map((v) => v[0]));
          // console.log(miny, maxy, minx, maxx)
          // console.log(lngInterpOffset, latInterpOffset)
          this.krigingData[key]["xlim"] = [minx - lngInterpOffset, maxx + lngInterpOffset];
          this.krigingData[key]["ylim"] = [miny - latInterpOffset, maxy + latInterpOffset];
        }
        // return defaultElement;
      })
      //     .then(element => {
      //   this.addCheckedElementLayer(element);
      //   this.isCanvasUpdate = true;
      //   this.showLayers = true;
      // });
      // this.krigingData = krigingData;
      console.log('krigingData:', this.krigingData);
      // console.log(this.myLabel,this.myLabel[0])
      // this.myLabel = myLabel;
    },
    setDefaultPolluion: function (data) {
      /**
       * @Description: 设置默认选中的污染物
       * @Auther: HLY
       * @Date: 2023/2/19
       */
      let pollutionDetail = {};
      if (this.pollutionMode === enums.pollutionMode.vocMode) {
        pollutionDetail = data.vocDetail;
      } else if (this.pollutionMode === enums.pollutionMode.metalMode) {
        // pollutionDetail = data.heavyMetalDetail;
        // console.log(pollutionDetail)
        this.defaultPollution = 'Sb';
      }
      // for (let element in pollutionDetail) {
      //   console.log(element);
      //   this.defaultPollution = element;
      //   break;
      // }
    },
    changeGridGap: function (gridGap) {
      /**
       * @Description: 切换格网大小
       * @Auther: HLY
       * @Date: 2023/2/18
       */
      //  隐藏前一个格网的polygon
      // console.log(this.$refs.centerMap.curGridOverallId)
      if (this.$refs.centerMap.curGridOverallId !== null || gridGap === '') {
        this.$refs.centerMap.switchGridDetailLayer();
      }
      this.$refs.centerMap.drawGrid(gridGap, this.taskId, this.hisCoordOffset);
    },
    getGridDetailByCoordinate: function (lng, lat, color, opacity = 0.6) {
      /**
       * @Description: 基于坐标获取格网大小的相关信息，用于修改polygon样式
       * @Auther: HLY
       * @Date: 2023/2/18
       */
      request.post('/grids/getGridDetailByCoordinate?gridGap=' + this.gridGap + '&lon=' + lng +
          '&lat=' + lat + '&taskId=' + this.taskId).then(res => {
        this.$refs.centerMap.setPolygonOptions(this.$refs.centerMap.polygonMap.get(res.data.gridOverallId).get(res.data.gridDetailId), color, opacity);
      })
    },
    getPollutionStandard: function (element) {
      /**
       * @Description: 获取当前元素污染标准
       * @Auther: HLY
       * @Date: 2023/2/19
       */
      request.get('/data/pollutantStandard').then(res => {
        this.pollutionStandard = res.data;
        // this.pollutionStandard =
      })
    },
    loadCarPos: function () {
      /**
       * @Description: 地图窗口显示到小车位置
       * @Auther: HLY
       * @Date: 2023/2/16
       */
      this.$refs.centerMap.zoom2CarPos();
    },
    startLifter: function () {
      /**
       * @Description: 启动电机
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      request.get('/orders/' + this.$refs.code.MESSAGE_TYPE_ORDER_ELEVATOR_DOWN).then(res => {
        let message = '升降机下降';
        this.$message({
          type: 'success',
          message: message
        });
      })
    },
    lifterUp: function () {
      /**
       * @Description: 将升降机归位（上升回原位）
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      request.get('/orders/' + this.$refs.code.MESSAGE_TYPE_ORDER_ELEVATOR_UP).then(res => {
        let message = '升降机上升';
        this.$message({
          type: 'success',
          message: message
        });
      })
    },
    stopLifter: function () {
      /**
       * @Description: 停止升降机
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      request.get('/orders/' + this.$refs.code.MESSAGE_TYPE_ORDER_ELEVATOR_STOP).then(res => {
        let message = '升降机停止';
        this.$message({
          type: 'success',
          message: message
        });
      })
    },
    measureMetal: function () {
      /**
       * @Description: 测量土壤元素含量
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      request.get('/orders/' + this.$refs.code.MESSAGE_TYPE_ORDER_HEAVY_MENTAL_RESTART).then(res => {
        let message = 'X射线荧光仪测量';
        this.$message({
          type: 'success',
          message: message
        });
      })
    },
    reGraph: function () {
      /**
       * @Description: 重新拍摄重金属仪显示界面
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      request.get('/orders/' + this.$refs.code.MESSAGE_TYPE_ORDER_OCR_REMAKE).then(res => {
        let message = 'X射线荧光仪测量界面获取成功';
        this.$message({
          type: 'success',
          message: message
        });
      })
    },
    reOCR: function () {
      /**
       * @Description: 重新识别重金属仪内容
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      request.get('/orders/' + this.$refs.code.MESSAGE_TYPE_ORDER_OCR_REREAD).then(res => {
        let message = '读取X射线荧光仪测量界面';
        this.$message({
          type: 'success',
          message: message
        });
      })
    },

    beforeUpload: function (curMenuStatus) {
      if (this.taskId > 0) {
      // if (this.curMode === enums.mode.runMode && this.curRunMode === enums.runModeChild.runOnMode) {
        console.log(this.taskId);
        let message = "已有任务正在执行，是否停止？";
        this.$confirm(message, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 取消执行
          let output = "已取消执行";
          console.log(this.taskId);
          request.put("/tasks/stop/" + this.taskId).then(res => {
            output = res['reason'];
            console.log(output);
            this.curMode = enums.mode.noMode;
            this.renew();
          })

          this.$message({
            type: 'success',
            message: output
          });
        })
      } else this.uploadTask(curMenuStatus);
    },

    uploadData: function () {
      /**
       * @Description: 将用户修改后的识别结果上传
       * @Auther: HLY
       * @Date: 2023/2/14
       */


      this.table2heavyMetalDetail()
      // console.log('metal-data',this.metalData)
      request.post('/data/updateOcr', JSON.stringify(this.metalData)).then(res => {
        let message = '成功写入测量结果';
        this.$message({
          type: 'success',
          message: message
        });
      })

    },

    cleanBoard: function () {
      /*
      清洁底板
       */
      // console.log('cleanBoard')
    },
    forward: function () {
      /**
       * @Description: 将用户修改后的识别结果上传
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      // console.log('forward')
      request.get('/orders/' + this.$refs.code.MESSAGE_TYPE_ORDER_MONITOR_FORWARD).then(res => {
        let message = '移动监测机器人前进';
        this.$message({
          type: 'success',
          message: message
        });
      })
    },
    backward: function () {
      /**
       * @Description: 后退
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      // console.log('backward');
      request.get('/orders/' + this.$refs.code.MESSAGE_TYPE_ORDER_MONITOR_BACKOFF).then(res => {
        let message = '移动监测机器人后退';
        this.$message({
          type: 'success',
          message: message
        });
      })
    },
    pause: function () {
      /**
       * @Description: 暂停
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      // console.log('pause');
      request.get('/orders/' + this.$refs.code.MESSAGE_TYPE_ORDER_MONITOR_STOP).then(res => {
        let message = '移动监测机器人暂停移动';
        this.$message({
          type: 'success',
          message: message
        });
      })
    },
    resetRaspberry1: function () {
      /**
       * @Description: 重启树莓派1
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      // console.log('resetRaspberry1');
      request.get('/orders/' + this.$refs.code.MESSAGE_TYPE_ORDER_RASPBERRY_RESTART).then(res => {
        let message = '重启传感器控制器';
        this.$message({
          type: 'success',
          message: message
        });
      })
    },
    resetRaspberry2: function () {
      /**
       * @Description: 重启树莓派2
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      // console.log('resetRaspberry2');
      request.get('/orders/' + this.$refs.code.MESSAGE_TYPE_ORDER_MONITOR_FORWARD).then(res => {
        let message = '重启升降机控制器';
        this.$message({
          type: 'success',
          message: message
        });
      })
    },
    heavyMetalDetail2Table: function (heavyMetalDetail) {
      /**
       * @Description: 将后台获取的OCR对象转为table数据
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param heavyMetalDetail:重金属ocr识别对象
       */
      let tableData = []
      for (let key in heavyMetalDetail) {
        if (key === "heavyMetalDetailId" || key === "pointId") {
          this.metalData[key] = heavyMetalDetail[key];
        } else {
          tableData.push({name: key, value: heavyMetalDetail[key]})
        }
      }
      this.$refs.dynamicTable.ocrTable = tableData;
    },

    table2heavyMetalDetail: function () {
      /**
       * @Description: 将修改后的ocr读取结果写入metalData，用于写入数据库
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      for (let i = 0; i < this.ocrTable.length; i++) {
        // console.log(this.ocrTable[i])
        this.metalData[this.ocrTable[i].name] = this.ocrTable[i].value;
      }
    },
    initWebSocket(wsURL) {
      /**
       * @Description: 初始化weosocket
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param wsURL:websocket地址
       */
      if (typeof WebSocket === 'undefined')
        return console.log('您的浏览器不支持websocket')
      this.websock = new WebSocket(wsURL)
      this.websock.onmessage = this.webSocketOnMessage
      this.websock.onopen = this.webSocketOnOpen
      this.websock.onerror = this.webSocketOnError
      this.websock.onclose = this.webSocketClose
    },
    webSocketOnOpen(data) {
      /**
       * @Description: 连接建立之后执行send方法发送数据
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      // let actions = {userType: 'adminUser', userId: 3}
      this.webSocketSend(JSON.stringify(data))
    },
    webSocketOnError() {
      /**
       * @Description: 连接建立失败重连
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      this.initWebSocket()
    },
    webSocketOnMessage(e) {
      // 数据接收
      let redata = JSON.parse(e.data);
      switch (redata.code) {
        case this.$refs.code.MESSAGE_TYPE_ACTIVE_TASK_STATE:
          /**
           * @Description:
           * @Auther: Tian
           * @Date: 2023/2/14
           */
          this.FinishPoint = redata.data.taskProcess;
          this.tempPD = this.PointData;
          this.tempPD[this.FinishPoint - 1][2] = "已完成";
          this.OriginPoint[0] = Math.round(this.OriginPoint[0] * 10000) / 10000;
          this.OriginPoint[1] = Math.round(this.OriginPoint[1] * 10000) / 10000;
          this.PassLength += this.Distance(this.tempPD[this.FinishPoint - 1][0], this.tempPD[this.FinishPoint - 1][1], this.OriginPoint[0], this.OriginPoint[1]);
          // console.log(this.tempPD[this.FinishPoint - 1]);
          // console.log(this.OriginPoint);
          this.OriginPoint = this.tempPD[this.FinishPoint - 1].slice(0, 2);
          if (this.FinishPoint !== this.tempPD.length) {
            this.tempPD[this.FinishPoint][2] = "正在前往";
          }
          this.taskProcess = Math.round(this.FinishPoint / this.PointData.length * 100);
          console.log(this.FinishPoint);
          if (this.FinishPoint === this.PointData.length) {
            this.taskProcess = 100;
            this.getTaskById2Kriging(this.taskId, this.hisCoordOffset);
            this.getWholePollutionByTaskId(this.taskId, this.wholeLabel, null);
            this.taskId = -1;
            this.TaskName = "任务：" + this.TaskName + "已完成";
            this.curRunMode = enums.runModeChild.runOffMode;
          }
          break;
        case this.$refs.code.MESSAGE_TYPE_NEW_MONITOR_STATE:
          /**
           * @Description: 3002,小车传输当前状态，包含坐标、风速、风向、温度、湿度、CO2、PM10、PM2.5、TVOC、甲醛、里程、监控画面
           * @Auther: HLY
           * @Date: 2023/2/14
           */
          //风力风向
          if (redata.data && redata.data.windDegree) this.windData.windDegree = redata.data.windDegree;
          if (redata.data && redata.data.windDirection) this.windData.windDirection = redata.data.windDirection;

          this.carData.carPosition = [redata.data.currentLongitude, redata.data.currentLatitude];
          this.$refs.centerMap.clearCarMarker();
          this.$refs.centerMap.showCarPosition([this.carData.carPosition[0] + this.coordOffset.lngOffset,
            this.carData.carPosition[1] + this.coordOffset.latOffset]);

          this.carData.speed = redata.data.speed;
          // console.log("speed", this.speed)
          //  更新温湿度、大气监测物浓度
          this.carData.temperature = redata.data.temperature;
          this.carData.humidity = redata.data.humidity;
          this.airData.co2 = redata.data.CO2;
          this.airData.pm10 = redata.data.PM10;
          this.airData.pm25 = redata.data.PM25;
          this.airData.tvoc_air = redata.data.TVOC;
          this.airData.ch2o = redata.data.formaldehyde;

          this.carData.mileage = redata.data.mileage;
          // this.taskId = redata.data.activeTaskId;
          // 监控画面URL
          this.foreGroundMonitorUrl = this.baseUrl + 'realTimeImages/' + redata.data.uploadFilePath + '?timestamp=' + new Date().getTime();
          this.boardMonitorUrl = this.baseUrl + 'realTimeImages/' + redata.data.processFilePath + '?timestamp=' + new Date().getTime();
          // console.log(this.carPosition)

          break;
        case this.$refs.code.MESSAGE_TYPE_FINISH_TASK_FLAG:
          /**
           * @Description: 任务完成
           * @Auther: HLY
           * @Date: 2023/2/14
           */
          this.isTaskRun = !redata.data.isFinished;
          console.log(this.isTaskRun)
          this.curRunMode = enums.runModeChild.runOffMode;
          break;
        case this.$refs.code.MESSAGE_TYPE_NEW_DETECTION_DATA:
          /**
           * @Description: 小车传输监测数据
           * @Auther: HLY
           * @Date: 2023/2/14
           */
          console.log('detection data:', redata.data);
          // 小车上传检测点数据，前端更新数据
          // let position = this.coordtransform.wgs84togcj02(redata.data.longitude, redata.data.latitude);
          // redata.data.longitude = position[0];
          // redata.data.latitude = position[1];
          this.$refs.centerMap.drawMonitorPoint(redata.data, this.coordOffset);
          this.roadSurfaceMonitorUrl = this.baseUrl + redata.data.taskId + '/' + redata.data.uploadSurface;
          this.XRayScreenMonitorUrl = this.baseUrl + redata.data.taskId + '/' + redata.data.uploadOcr;
          this.airData.tvoc_soil = redata.data.hasOwnProperty('vocDetail') ? redata.data.vocDetail.benzene : -1;
          // OCR识别结果
          if (redata.data.hasOwnProperty('heavyMetalDetail')) {
            this.heavyMetalDetail2Table(redata.data.heavyMetalDetail);
          }
          this.defaultPollution='Sb';
          // if (!this.defaultPollution) {
          //   this.setDefaultPolluion(redata.data);
          //   // this.checkElementLayer.push(this.defaultPollution);
          // }
          if (this.gridGap !== '') {
            let detectionValue = this.pollutionMode === enums.pollutionMode.metalMode ?
                redata.data.heavyMetalDetail[this.defaultPollution] :
                redata.data.vocDetail[this.defaultPollution];
            console.log('defaultPollution:', this.defaultPollution);
            console.log('pollutionStandard:', this.pollutionStandard);
            console.log('planData:',this.planData);
            let standard = Number(this.planData.landType) === 1 ? [
                this.pollutionStandard[this.defaultPollution].firstClassLandFiltStandard,
                  this.pollutionStandard[this.defaultPollution].firstClassLandControlStandard] :
                [this.pollutionStandard[this.defaultPollution].secondClassLandFiltStandard,
                  this.pollutionStandard[this.defaultPollution].secondClassLandControlStandard];
            console.log('standard',standard)
            let color = this.$refs.centerMap.findColor(standard, detectionValue);
            console.log('color:', color);
            this.getGridDetailByCoordinate(redata.data.longitude, redata.data.latitude, color);
          }
          break;
        case this.$refs.code.MESSAGE_TYPE_NEW_TASK:
          /**
           * @Description:
           * @Auther: HLY
           * @Date: 2023/2/14
           */
          this.$confirm('您有一个新任务，是否立即开始编辑?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'info'
          }).then(() => {
            this.initAll();
            this.curMenuStatus = enums.menuStatus.taskStatus;
            this.handleClick(this.coordTrans(redata.data.detectionPointList, true), redata.data.taskId, redata.data.taskName, redata.data.dataType, null);
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消执行'
            });
          });
          break;
      }
      console.log('接收的数据', redata);
    },
    webSocketSend(Data) {
      /**
       * @Description: 数据发送
       * @Auther: HLY
       * @Date: 2023/2/14
       * @param Data:传输数据
       */
      this.websock.send(Data)
    },
    webSocketClose(e) {
      /**
       * @Description: 关闭websocket连接
       * @Auther: HLY
       * @Date: 2023/2/14
       */
      // this.$alert('移动机器人不在线，请连接机器人！', '', {type: "warning"});//  警告
      console.log('断开连接', e)
    },
  }
}
</script>

<style lang="less">
#data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;
  display: flex;
  flex-direction: column;

  #dv-full-screen-container {
    background-image: url('../assets/img.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .pollution-switch {
    position: absolute;
    right: 2%;
    top: 6%;

    .el-radio-button__inner {
      font-weight: bold;
    }
  }

  .top-header {
    height: 10%;
    //flex-grow: 1;
  }

  .main-container {
    //width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    //flex: 1;
    //display: flex;
    //padding: 20px;
    //box-sizing: border-box;
    //display: flex;
    //flex-grow: 0;


    .menu-container {
      width: 20%;
      height: 100%;
      font-weight: bold;

      //.el-menu-vertical-demo {
      //  width: 80%;
      //  height: 96%;
      //  //margin: 10%;
      //  border: 0 !important;
      //  text-align: left;
      //  top: 3%;
      //  left: 10%;
      //}
    }

    .center-container {
      display: flex;
      flex-direction: column;
      width: 45%;
      //width: calc(45% - 20px);
      //height: 100%;
      //margin: 0 10px 0 10px;


      .map-container {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        //flex-grow: 1;

      }

      .device-control-container {
        //width: 100%;
        height: 50%;
        //flex-grow: 2;
        display: flex;
        flex-direction: row;
        //box-sizing: border-box;

        .car-control-container {
          //height: 100%;
          //float: left;
          width: 60%;
          //box-sizing: border-box;
          //flex-grow: 2;
        }

        .car-status-container {

          //height: 40%;
          display: flex;
          //box-sizing: border-box;
          //flex-grow: 1;
          flex-direction: column;
          //float: left;
          width: 40%;

          //.title {
          //  top: 2%;
          //  height: 10%;
          //}
          #car-status {
            width: 100%;
            height: 50%;
            //height: 95%;
            //margin-top: 20px;
            display: flex;
            flex-direction: row;
            //flex-grow: 1;
            //box-sizing: border-box;
            //padding-bottom: 20px;
          }

          .btn-box {
            width: 100%;
            height: calc(~"50%-65px");
            //flex-grow: 1;
            //display: flex;
            //align-items: center;
            //justify-content: center;

          }
        }
      }
    }

    .block-content {
      width: 35%;
      height: 100%;
      //flex: 1;
      display: flex;
      flex-direction: column;
      //box-sizing: border-box;
      //padding-left: 20px;
      .car-monitor-container {
        height: 40%;
        //display: flex;
      }

      .monitor-data-container {
        //display: flex;
        height: 40%;
        //box-sizing: border-box;
      }

      .device-status-container {
        height: 20%;
        //box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        #device-btn-box {
          height: 100%;
          margin-top: 5px;
        }
      }
    }

  }

  .gaode-map {
    height: 42%;
    width: 43%;
    position: absolute;
    top: 11.6%;
    left: 21%;
    display: flex;
    z-index: 1;
  }

  .camera-box {
    height: 90%;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    //flex-grow: 0;
    //box-sizing: border-box;
  }

  .air-box {
    margin-top: 20px;
    width: 96%;
    height: 90%;
  }

  .voc-box {
    margin-top: 10px;
  }

  #m-item {
    padding: 0 !important;
    min-width: 0;
  }

  .el-submenu__title {
    padding: 0 !important;
  }

  .el-submenu .el-menu-item {
    min-width: 0;
  }

  .project-container .el-dialog__body {
    height: 50vh;
    overflow: auto;
  }

  .task-container .el-dialog__body {
    height: 50vh;
    overflow: auto;
  }

  .btn-container {
    height: 32px;
    display: flex;
    position: absolute;
    top: 47%;
    right: 36.5%;
    z-index: 2;
  }

  .add-buildings {
    position: absolute;
    top: 13.3%;
    right: 51%;
    z-index: 2;
  }

  .locate-box {
    //background-color: white;
    position: absolute;
    //border: lightslategrey;
    //border-radius: 5px;
    top: 20%;
    left: 21.9%;
    z-index: 2;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    //object-fit: contain;
    .el-icon-my-locate {
      background: url("../assets/locate.png") center no-repeat;
      background-size: cover;
      //width: 100%;
      //height: 100%;
    }

    .el-icon-my-locate:before {
      content: "\8d3a";
      font-size: 14px;
      visibility: hidden;
    }
  }

  .canvas-layer-switch {
    position: absolute;
    top: 13.3%;
    right: 42%;
    z-index: 2;
  }

  .grid-select {
    position: absolute;
    top: 18.3%;
    right: 42%;
    z-index: 2;
  }

  .kriging-layer-check-boxes {
    background-color: white;
    //width: 115px;
    padding: 5px;
    position: absolute;
    top: 25%;
    right: 37.5%;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    //padding-top: 5px;
    z-index: 2;
  }

  .table-container {
    width: 100%;
    height: 100%;

    .title {
      top: 2%;
      height: 10%;
    }

    .plan-table {
      padding: 5px 30px 30px 30px;
    }
  }
}
</style>