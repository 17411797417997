<template>
  <div id="humiture-chart">
    <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
      <div class="temperature-span">温度：<span>{{ temperature ? parseInt(temperature) : '' }}</span>{{temperature?'℃':'无数据'}}</div>
      <div class="humidity-span">湿度：<span>{{ humidity ? parseInt(humidity) : '' }}</span>{{humidity?'&nbsp%':'无数据'}}</div>
    </div>
    <!--    <el-container style="height: 100%">-->
    <!--&lt;!&ndash;      <el-header>&ndash;&gt;-->
    <!--&lt;!&ndash;        <div class="chart-title">温湿度</div>&ndash;&gt;-->
    <!--&lt;!&ndash;      </el-header>&ndash;&gt;-->
    <!--      <el-main style="padding: 0">-->
    <!--        -->
    <!--      </el-main>-->
    <!--    </el-container>-->


  </div>
</template>

<script>
export default {
  name: "Humiture",
  props: ["temperature", "humidity"]
}
</script>

<style lang="less">
#humiture-chart {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  //margin-top: 20px;
  //background-color: rgba(6, 30, 93, 0.5);
  //border-top: 2px solid rgba(1, 153, 209, .5);

  letter-spacing: 4px;

  .chart-title {
    font-weight: bold;
    font-size: 20px;
    color: #00ccff;
    text-align: center;
    //margin-top: 15px;
  }

  #humiture-main {
    height: calc(~"100% - 60px");
  }

  .temperature-span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    height: 40px;
    font-size: 20px;
    //margin-bottom: 30px;


    span {
      font-size: 30px;
      color: #00c0ff;
      //margin-right: 15px;
    }
  }

  .humidity-span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    height: 40px;
    font-size: 20px;

    span {
      font-size: 30px;
      color: #00c0ff;
      //margin-right: 15px;
    }
  }
}
</style>