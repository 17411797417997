const enums = {
    menuStatus: {
        noStatus: '无选中状态',
        planStatus: '选中方案菜单',
        taskStatus: '选中任务菜单'
    },
    mode: {
        noMode: '无',
        createMode: '新建模式',
        checkMode: '查看模式',
        correctCoordMode: '校正坐标模式',
        runMode: '任务运行模式',
    },
    runModeChild: {
        noRunMode: '无',
        runOnMode: '任务运行中',
        runOffMode: '任务完成'
    },
    checkModeChild: {
        noCheckMode: '无',
        checkCreateMode: '新增路径点',
        checkEditMode: '编辑路线',

    },
    monitorStatus: {
        noStatus: '无状态',
        free: '空闲状态',
        run: '任务状态'
    },
    pollutionMode: {
        noMode: '无',
        metalMode: '重金属',
        vocMode: 'VOC'
    }
}

export {
    enums
}