<template>
  <div class="TaskManage">
    <a href="#" style="--clr:#00ccff"><span>任务管理</span></a>
  </div>

</template>

<script>
export default {
  name: "TaskManage"
}
</script>

<style lang="less">
//*{
//  margin: 0;
//  padding: 0;
//  box-sizing: border-box;
//}
.TaskManage {
  display: flex;
  justify-content: center;
  align-items: center;
  //min-height: 100vh;
  height: 10%;
  //width: 80px;
  //flex-direction: column;


  a {
    height: 40px;
    width: 80px;
    position: relative;
    //padding: 20px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    //margin: 40px;
    transition: 1s;
    text-decoration: none;
    overflow: hidden;
    //border: 1px solid #09b7e9;
    /*-webkit-box-reflect: below 1px linear-gradient(transparent, transparent, #0004);*/
  }

  a:hover {
    background: var(--clr);
    box-shadow: 0 0 10px var(--clr),
    0 0 30px var(--clr),
    0 0 60px var(--clr),
    0 0 100px var(--clr);
  }

  a:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 100px;
    background: var(--clr);
    transition: 1s;
    animation: animate 2s linear infinite;
    //animation-delay: calc(0.33s * var(--i));
  }
  //
  a:hover::before {
    width: 120%;
  }
  //
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  a::after {
    content: '';
    position: absolute;
    inset: 4px;
    background: #0e1538;
    transition: 0.5s;
  }

  a:hover::after {
    background: var(--clr);
  }

  a span {
    position: relative;
    z-index: 1;
    //font-size: 2em;
    color: #fff;
    opacity: 1;
    //text-transform: uppercase;
    letter-spacing: 4px;
    transition: 0.5s;
  }

  a:hover span {
    opacity: 1;
  }
}

</style>