<template>
  <div id="ranking-board">
    <div class="ranking-board-title">污染物浓度(PPM)</div>
    <dv-scroll-ranking-board :config="config" :key="ChangeTime"/>
  </div>
</template>

<script>
export default {
  name: 'RankingBoard',
  props: ['HeavyMetalData'],
  data() {
    return {
      config: {
        data: [],
        rowNum: 0
      },
      ChangeTime: 0
    }
  },
  watch: {
    HeavyMetalData(item1, item2) {
      this.config.data = [];
      for (const i in item1) {
        if (item1[i] != null && i !== "heavyMetalDetailId" && i !== "pointId") {
          this.config.data.push({"name": i, "value": item1[i]});
        }
      }
      this.config.rowNum = this.config.data.length;
      this.ChangeTime++;
    }
  },
  methods: {
    pieOutlineFunc () {
      var _this=this;
      window.addEventListener('resize', function(e){
        _this.$nextTick(()=>{
          console.log(_this.$refs.pie2);
          _this.changeTimes++;
        })
      })
    }
  },
  mounted () {
    this.pieOutlineFunc();
  },
}
</script>

<style lang="less">
#ranking-board {
  height: calc(~"39% - 26px");
  //margin-top:3%;
  //margin-left: 2%;
  width: 96%;
  //box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
  //background-color: rgba(6, 30, 93, 0.5);
  //border-top: 2px solid rgba(1, 153, 209, .5);
  box-sizing: border-box;
  padding: 0px 30px;

  .ranking-board-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    letter-spacing: 4px;
  }

  .dv-scroll-ranking-board {
    flex: 1;
  }
}
</style>