<template>
  <div class="air-container">
    <el-row :gutter="25" style="padding-left: 30px;">
      <el-col :span="4">
        <div><img src="../assets/co2.png"></div>
      </el-col>
      <el-col :span="5">
        <div><img src="../assets/CH2O-Outlined.png"></div>
      </el-col>
      <el-col :span="5">
        <div><img src="../assets/pm2.5.png"></div>
      </el-col>
      <el-col :span="5">
        <div><img src="../assets/pm10.png"></div>
      </el-col>
      <el-col :span="5">
        <div><img src="../assets/tvoc.png"></div>
      </el-col>
    </el-row>
    <el-row :gutter="25" style="padding-left: 30px;">
      <el-col :span="4">
        <div><span>CO₂</span></div>
      </el-col>
      <el-col :span="5">
        <div><span>甲醛</span></div>
      </el-col>
      <el-col :span="5">
        <div><span>PM2.5</span></div>
      </el-col>
      <el-col :span="5">
        <div><span>PM10</span></div>
      </el-col>
      <el-col :span="5">
        <div><span>TVOC - AIR</span></div>
      </el-col>

    </el-row>
    <!--    数据-->
    <el-row :gutter="25" style="padding-left: 30px;margin-top: 10px">
      <el-col :span="4">
        <div class="air-data"><span>{{ co2 !== -1 ? parseInt(co2) : '' }}</span>{{ co2 !== -1 ? '&nbspppm' : '无数据' }}
        </div>
      </el-col>
      <el-col :span="5">
        <div class="air-data">
          <span>{{ ch2o !== -1 ? parseInt(ch2o) : '' }}</span>{{ ch2o !== -1 ? '&nbspug/m³' : '无数据' }}
        </div>
      </el-col>
      <el-col :span="5">
        <div class="air-data">
          <span>{{ pm25 !== -1 ? parseInt(pm25) : '' }}</span>{{ pm25 !== -1 ? '&nbspug/m³' : '无数据' }}
        </div>
      </el-col>
      <el-col :span="5">
        <div class="air-data">
          <span>{{ pm10 !== -1 ? parseInt(pm10) : '' }}</span>{{ pm10 !== -1 ? '&nbspug/m³' : '无数据' }}
        </div>
      </el-col>
      <el-col :span="5">
        <div class="air-data">
          <span>{{ tvoc_air !== -1 ? parseInt(tvoc_air) : '' }}</span>{{ tvoc_air !== -1 ? '&nbspug/m³' : '无数据' }}
        </div>
      </el-col>
    </el-row>

    <!--    <div style="margin-top: 2em">-->
    <!--      <el-row :gutter="20" style="padding-left: 3em;">-->

    <!--        <el-col :span="6">-->
    <!--          <div ><img src="../assets/TVOC-Outlined.png"></div>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--      <el-row :gutter="20" style="padding-left: 3em;">-->

    <!--        <el-col :span="6">-->
    <!--          <div ><span>TVOC-Soil</span></div>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--      <el-row :gutter="20" style="padding-left: 3em;margin-top: 20px">-->

    <!--        <el-col :span="6">-->
    <!--          <div class="air-data"><span>{{ tvoc_soil }}</span>&nbspmmol/L</div>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--    </div>-->


  </div>
</template>

<script>
export default {
  name: "AirData",
  props: ['co2', 'ch2o', 'tvoc_air', 'pm25', 'pm10'],
}
</script>

<style lang="less">
.air-container {
  width: 100%;
  height: 100%;
  //display: flex;
  text-align: center;

  .air-data {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    height: 40px;
    font-size: 15px;

    span {
      font-size: 25px;
      color: #00c0ff;
      //margin-right: 15px;
    }
  }
}
</style>