<template>
<!--  <div class="left-chart-2">-->
<!--&lt;!&ndash;    <div class="lc2-header">移动机器人</div>&ndash;&gt;-->

<!--&lt;!&ndash;    <div class="lc2-details">在线数<span>{{ onlineMonitorUserNum }}</span></div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="lc2-details">空闲数<span>{{ freeMonitorNum }}</span></div>&ndash;&gt;-->
<!--    <dv-decoration-2 style="height:10px;"/>-->
<!--    <div class="lc2-select">-->
<!--      <el-select v-model="monitorId" @change="selectMonitor" clearable placeholder="请选择机器人"-->
<!--                 :popper-append-to-body="false">-->
<!--        <el-option-->
<!--            v-for="item in options"-->
<!--            :key="item.monitorId"-->
<!--            :label="item.monitorId"-->
<!--            :value="item.monitorId">-->
<!--        </el-option>-->
<!--      </el-select>-->
<!--&lt;!&ndash;      <p>机器人：{{options[1].robotName}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;      <p>机器人：{{options[1].monitorId}}号</p>&ndash;&gt;-->
<!--    </div>-->
<!--    <div class="lc2-details" style="margin-top: 10px">当前状态<span style="font-size: larger">{{ curMonitorStatus }}</span>-->
<!--    </div>-->

<!--    <dv-decoration-2 style="height:10px;margin-top: 10px"/>-->
<!--  </div>-->
</template>

<script>
import request from "@/utils/request";
import {enums} from "@/utils/common";

export default {
  name: "RightMiddleChart",
  data() {
    return {
      enums: enums,
      onlineMonitorUserNum: 0, //在线的机器人数量
      freeMonitorNum: 0, // 空闲状态的机器人数量
      curMonitorStatus: enums.monitorStatus.noStatus,
      options: [],
      monitorId: '' // 机器人ID
    }
  },
  mounted() {
    // this.getRobots();
    // this.getConnectionMonitorUser();
    // this.selectMonitor(this.monitorId);
  },
  methods: {

    // getRobots: function () {
    //   /*
    //   获取当前接入的移动监测平台
    //    */
    //   request.get('/robots/activeRobots').then(res => {
    //     let freeMonitorNum = 0;
    //     for (let key in res.data){
    //       freeMonitorNum += res.data[key].currentState === 0 ? 1 : 0;
    //     }
    //     this.freeMonitorNum = freeMonitorNum;
    //
    //     this.options = res.data;
    //     this.options = {...this.options};
    //
    //   })
    // },
    // getConnectionMonitorUser: function () {
    //   // 获取已连接的机器人数量
    //   request.get('/connection/monitorUser').then(res => {
    //     this.onlineMonitorUserNum = Object.keys(res.data).length;
    //   })
    // },
    // selectMonitor: function (monitorId) {
    //   if (typeof monitorId === "string") {
    //     //  未选择任务
    //     this.curMonitorStatus = enums.monitorStatus.noStatus;
    //   } else {
    //     // 基于机器人ID获取机器人状态
    //     this.curMonitorStatus = this.options[monitorId].currentState === 0 ? enums.monitorStatus.free : enums.monitorStatus.run;
    //   }
    // }
  }
}
</script>

<style lang="less">
.left-chart-2 {
  width: 100%;
  height: 17%;
  display: flex;

  flex-direction: column;

  .lc2-header {
    height: 20px;
    line-height: 20px;
    text-indent: 20px;
    margin-top: 10px;
    font-size: larger;
    font-weight: bold;
  }

  .lc2-details {
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;
    margin-top: 10px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .lc2-select {
    height: 40px;
    font-size: 16px;
    display: flex;
    margin: 13px 0 0 20px;

    .el-input__inner {
      background-color: #030409;
      color: #fff;
      border: 1px solid #0e4b93;
    }

    .el-select-dropdown {
      background-color: #030409;
      border: 1px solid #0e4b93;

      .el-select-dropdown__item {
        color: white;
        background-color: #030409;
        text-align: left;
      }

      .el-select-dropdown__item:hover {
        background-color: rgba(0, 225, 219, 0.690196078431373);
      }
    }
  }


  .lc2-chart {
    height: calc(~"100% - 80px");
  }
}
</style>