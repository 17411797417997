<template>
  <div id="container" class="gaodeMap">
    <!--    <el-button type="primary" id="test" @click="drawOverlay('polyline')">主要按钮</el-button>-->
    <div class="searchDiv">
      <el-input
          id="searchInput"
          placeholder="请输入地点"
          v-model="searchWords"
          clearable>
      </el-input>
    </div>
    <!--    <infoWindow ref="infoWindow" v-show="infoWindow!=null"></infoWindow>-->

  </div>


</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import ElementUI from "element-ui";
import Alert from "@/components/Alert";
// import InfoWindow from "@/components/InfoWindow";
import kriging from "@/utils/kriging"
import coordtransform from 'coordtransform';
import request from "@/utils/request";

export default {
  name: "CenterMap",
  components: {
    ElementUI,
    Alert,
    // InfoWindow
  },
  data() {
    return {
      tabMap: null,
      map: null, //高德地图实例
      canvas: null,
      placeSearch: null, // 地点搜索工具实例
      searchWords: '', //搜索内容
      mouseTool: null, //鼠标绘制工具实例
      pointMouseTool: null,//点标记绘制工具实例
      pointlayer: null,
      markerGroup: null, //点标记群组
      newTaskMarkers: [], //保存绘制点
      rawMarkers: [], //原始点坐标集
      polyline: null, // 折线对象
      polyEditor: null, // 折线编辑工具实例
      auto: null, // AutoComplete实例
      carMarker: null, // 小车点标记
      monitorMarkers: [],//监测点标记列表
      // infoWindow: null,
      coordtransform: null,
      canvasLayers: {}, //canvasLayer图层集
      polygonMap: null,
      curGridOverallId: null
    }
  },
  watch: {
    polyline: function (curPolyline) {
      this.$emit('update:polyline', curPolyline);
    },
    newTaskMarkers: function (curNewTaskMarkers) {
      this.$emit('update:newTaskMarkers', curNewTaskMarkers);
    },
    rawMarkers: function (curRawMarkers) {
      this.$emit('update:rawMarkers', curRawMarkers);
    },
  },
  beforeMount() {
    this.$emit('update:polyline', this.polyline);
    this.$emit('update:newTaskMarkers', this.newTaskMarkers);
    this.$emit('update:rawMarkers', this.rawMarkers);
  },
  mounted() {
    // 高德地图安全密匙
    window._AMapSecurityConfig = {
      securityJsCode: '72247c63314a9de3a628f29dfca769fa'
    };
    this.$nextTick(() => {

      // this.loadKriging();
      this.initMap();//初始化时加载地图及其控件

      // this.addCanvasLayer();
    });
  },
  methods: {
    initMap() {
      /*
      初始化地图，加载插件
      定位到当前所在城市，加载地图搜索，加载绘图工具
       */
      // let that = this;
      AMapLoader.load({
        key: "594025cc47f0b5f3e7188304ef6c6d8b",              // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.MouseTool',//鼠标绘制工具
          'AMap.ToolBar',//工具箱（左上角放大、放小）
          'AMap.PolyEditor',//折线编辑工具
          'AMap.Scale',//比例尺
          'AMap.MapType',//地图图层选择
          'AMap.PlaceSearch',//搜索地点
          'AMap.CitySearch',//定位到用户城市
          'AMap.AutoComplete',
          // 'AMap.CanvasLayer'
        ],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        // this._AMap = AMap;
        this.tabMap = AMap;
        // console.log(this.tabMap)
        this.map = new this.tabMap.Map('container', {
          zoom: 16,
          center: [115.944, 39.756],
          resizeEnable: true,
          // rotation: -15, //地图顺时针旋转角度，取值范围 [0-360] ，默认值：0
          // pitch: 42, //俯仰角度，默认 0，最大值根据地图当前 zoom 级别不断增大，2D地图下无效 。
          // viewMode: '3D', //开启3D视图,默认为关闭
        });


        // that.initCitySearch(this.tabMap); //定位到所在城市
        this.initPlaceSearch(); //初始化地图搜索
        this.initMouseTool(); //初始化绘图工具
        this.initPointMouseTool();//初始化定标点绘制工具

        //加载地图类型切换插件
        this.map.addControl(new this.tabMap.MapType({
          defaultType: 1,
          showRoad: true
        })) //设置地图类型
        //加载工具箱
        this.map.addControl(new this.tabMap.ToolBar({
          position: 'LT'
        }));
        //加载比例尺
        this.map.addControl(new this.tabMap.Scale());
        this.coordtransform = require('coordtransform');

        // let gcj02_position = this.coordtransform.wgs84togcj02(114.35277777777777, 30.552222222222223);//将WGS84转为GCJ-02
        // console.log(gcj02_position)


        // this.drawMonitorPoint({
        //   longitude: 114.358,
        //   latitude: 30.528
        // })
      }).catch(e => {
        console.log(e);
      })
    },

    buildingLayers(data) {
      // let path1 = [
      //   [114.3543, 30.5304],
      //   [114.3543, 30.5301],
      //   [114.3549, 30.5301],
      //   [114.3549, 30.5304]
      // ];
      // let polygon1 = new this.tabMap.Polygon({
      //   path: path1,
      //   strokeColor: "#000000",
      //   strokeWeight: 3,
      //   strokeOpacity: 0.8,
      //   fillOpacity: 0.9,
      //   fillColor: '#475557',
      //   zIndex: 50,
      // });
      //
      // this.map.add(polygon1);
      for (let i = 0; i < data.data.length; i++) {
        let polygon = new this.tabMap.Polygon({
          path: data.data[i],
          strokeColor: "#000000",
          strokeWeight: 3,
          strokeOpacity: 0.8,
          fillOpacity: 0.9,
          fillColor: '#475557',
          zIndex: 50,
        });
        this.map.add(polygon);
      }
    },

    search(e) {
      /*
      搜索选中的地址，并调整地图视角到搜索点
       */
      this.placeSearch.setCity(e.poi.adcode);
      this.placeSearch.search(e.poi.name) //关键字查询查询
      if (e.poi.location !== undefined) {
        this.map.setZoomAndCenter(15, [e.poi.location.lng, e.poi.location.lat])
      }
    },
    clearPlaceSearchMarker() {
      /*
      清除搜索后产生的点标记与输入内容
       */
      this.placeSearch.clear();
      this.searchWords = '';
    },
    initCitySearch() {
      /*
      定位到当前用户所在城市
       */
      let map = this.map;
      var citysearch = new this.tabMap.CitySearch();
      citysearch.getLocalCity(function (status, result) {
        if (status === 'complete' && result.info === 'OK') {
          if (result && result.city && result.bounds) {
            // console.log(result)
            var citybounds = result.bounds;
            //地图显示当前城市
            map.setBounds(citybounds);
            map.setZoom(14)
          }
        }
      });
    },
    initPlaceSearch() {
      /*
      初始化模糊搜索和查询
       */
      let autoOptions = {
        input: 'searchInput'
      }
      this.auto = new this.tabMap.Autocomplete(autoOptions)

      this.placeSearch = new this.tabMap.PlaceSearch({
        // pageSize: 1, // 单页显示结果条数
        // pageIndex: 1, // 页码
        map: this.map, // 展现结果的地图实例
        // panel: 'panel', // 结果列表将在此容器中进行展示。
        // autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
      })
      this.auto.on('select', this.search)
    },
    initMouseTool: function () {
      /*
      初始化鼠标绘图工具
       */
      this.mouseTool = new this.tabMap.MouseTool(this.map)
      this.mouseTool.on('draw', (overlay) => {
        // 根据鼠标绘制的覆盖物 生成Polygon实例
        let position = overlay.obj._position
        // console.log('position:', position)
        //将鼠标绘制的点标记的坐标添加到newTaskMarkers
        // console.log(position)
        this.newTaskMarkers.push([position.lng, position.lat])
        if (this.newTaskMarkers.length > 1) {
          this.drawPolyline(this.newTaskMarkers);
        }


      })
    },
    initPointMouseTool: function () {
      /*
      初始化定标点标记绘制工具，令定标点仅存在一个
       */
      this.pointMouseTool = new this.tabMap.MouseTool(this.map)
      this.pointMouseTool.on('draw', (overlay) => {
        //将鼠标绘制的定标点的坐标保存到pointlayer
        if (this.pointlayer) {
          this.map.remove(this.pointlayer);
          this.pointlayer = null;
        }
        this.pointlayer = overlay.obj;
      })
    },
    clearPointMouseTool: function () {
      /*
      清除定标点标记
       */
      this.pointMouseTool.close(true);
      this.pointlayer = null;
    },

    clearDraw: function (clearPoi, clearMarkerGroup, clearPoly) {
      /*
      清除地图上的点标记（mouseTool与makerGroup）、折线，并将工具类关闭、data数据重置
      clearPoi（true时，清除鼠标工具绘制的点）
      clearMarkerGroup（true且不为空时，清除markerGroup绘制的点）
      clearPoly（true且不为空时，清除绘制的折线）
       */
      if (clearPoi) {
        this.mouseTool.close(true);
        this.newTaskMarkers = [];
        this.pointMouseTool.close(true);
        this.pointlayer = null;
      }
      // console.log('marker-group',this.markerGroup)
      if (clearMarkerGroup && this.markerGroup !== null) {
        this.map.remove(this.markerGroup);
        this.markerGroup = null;
      }
      this.clearPlaceSearchMarker(); // 清除搜索产生的点标记
      //清除polyline对象，若存在折线编辑实例则清除
      if (clearPoly && this.polyline != null) {
        this.map.remove(this.polyline);
        this.polyline = null;
        if (this.polyEditor != null) {
          this.polyEditor.close();
          this.polyEditor = null;
        }
      }
    },

    //显示小车当前所在位置
    showCarPosition: function (position) {
      /*
      绘制小车所在位置
       */
      let gcj02_position = this.coordtransform.wgs84togcj02(position[0], position[1]);//将WGS84转为GCJ-02
      this.carMarker = new this.tabMap.Marker({
        position: new this.tabMap.LngLat(gcj02_position[0], gcj02_position[1]),
        anchor:'center',
        // offset: new this.tabMap.Pixel(-9, 10),
        icon:
            new this.tabMap.Icon({
              size: new this.tabMap.Size(32, 32),
              image: require('../assets/car.png'),
              imageSize: new this.tabMap.Size(32, 32)
            }), // 添加 Icon 图标 URL
      });
      this.map.add(this.carMarker);
    },
    zoom2CarPos: function () {
      /**
       * @Description: 窗口定位到小车位置
       * @Auther: HLY
       * @Date: 2023/2/16
       */
      if (this.carMarker) {
        this.map.setFitView(this.carMarker);
      }

    },
    clearCarMarker: function () {
      /*
      清除小车标记
       */
      if (this.carMarker) {
        this.map.remove(this.carMarker);
        this.carMarker = null;
      }
    },
    drawPoints: function (points) {
      /*
      绘制点集
       */
      //若存在markerGroup则清除并重置
      if (this.markerGroup != null) {
        this.map.remove(this.markerGroup);
        this.markerGroup = null;
      }
      //将历史巡检路线的监测点加入data，用于之后的编辑
      this.newTaskMarkers = points;
      //创建markerGroup并绘制
      let pointList = [];
      for (const point of this.newTaskMarkers) {
        // console.log(point)
        //是否需要将第一个点使用不同的样式##
        let marker = new this.tabMap.Marker({
          map: this.map,
          position: new this.tabMap.LngLat(point[0], point[1]),
        });
        pointList.push(marker)
      }
      this.markerGroup = new this.tabMap.OverlayGroup(pointList);
      this.map.add(this.markerGroup);
    },
    drawPolyline: function (points, isSetFitView = false) {
      /*
      基于点集绘制折线
       */
      // console.log(points)
      if (this.polyline) {
        this.map.remove(this.polyline);
      }
      this.polyline = new this.tabMap.Polyline({
        path: points,
        isOutline: true,
        outlineColor: '#ffeeff',
        borderWeight: 3,
        strokeColor: "#3366FF",
        strokeOpacity: 1,
        strokeWeight: 6,
        // 折线样式还支持 'dashed'
        strokeStyle: "solid",
        // strokeStyle是dashed时有效
        strokeDasharray: [10, 5],
        lineJoin: 'round',
        lineCap: 'round',
        zIndex: 50,
      });
      this.map.add(this.polyline);
      if (isSetFitView) {
        this.map.setFitView(this.polyline);
      }

    },
    startPolylineEdit: function () {
      /*
      点击编辑巡检路线后，打开折线编辑器
      当data中不存在polyline时，返回警报
       */
      if (this.polyline) {
        // 创建折线后，创建折线编辑器
        this.polyEditor = new this.tabMap.PolyEditor(this.map, this.polyline);
        this.polyEditor.open();
        this.$message({
          type: 'info',
          message: '开始编辑监测路线'
        });
      } else {
        this.$message({
          type: 'warning',
          message: '不存在监测路线，请确认已创建监测路线'
        });
      }

    },
    finishPolylineEdit: function (isEdit) {
      /*
      关闭折线编辑器
       */
      this.polyEditor.close()
      return false;
    },
    // setInfoWindows: function (e) {
    //   /*
    //   创建信息窗口，展示监测数据点的数据
    //    */
    //   // var that = this
    //   // console.log('infoWindow-monitorData:', e.target.getExtData())
    //   let infoWindow = new this.tabMap.InfoWindow({
    //     isCustom: true,
    //     content: this.$refs.infoWindow.$el,
    //     offset: new this.tabMap.Pixel(0, -25)
    //   });
    //
    //   //new AMap.LngLat(monitorData.longitude, monitorData.latitude)
    //   infoWindow.open(this.map, e.target.getPosition());
    //   this.infoWindow = infoWindow;
    //   // 调用组件方法，初始化组件页面的infoWindow等数据
    //   this.$refs.infoWindow.initialize({
    //     monitorData: e.target.getExtData(),
    //     infoWindow: this.infoWindow
    //   });
    // },
    drawMonitorPoint: function (monitorData, offset) {
      /*
      绘制监测数据点，并设定点标记的点击事件，打开信息窗口
       */
      // console.log('monitorData:', monitorData)
      if (monitorData === null || monitorData.length === 0) {
        return;
      }
      let gcj02_position = this.coordtransform.wgs84togcj02(monitorData.longitude + offset.lngOffset,
          monitorData.latitude + offset.latOffset);//将WGS84转为GCJ-02
      let marker = new this.tabMap.Marker({
        map: this.map,
        position: new this.tabMap.LngLat(gcj02_position[0], gcj02_position[1]),
        icon: new this.tabMap.Icon({
          image: require('../assets/green-marker.png'),
          imageOffset: new this.tabMap.Pixel(-1, 0),
          imageSize: new this.tabMap.Size(20, 25)
        }), // 添加 Icon 图标 URL
        zooms: [10, 19],
        anchor: 'bottom-center',
        extData: monitorData
      });
      this.monitorMarkers.push(marker)
      this.map.add(marker);
      // marker.on('click', (e) => {
      //   // console.log('e:', e)
      //   this.setInfoWindows(e);
      // })
    },
    showMonitorPoints: function () {
      for (let marker in this.monitorMarkers) {
        this.map.add(marker);
      }
    },
    cleanMonitorPoints: function () {
      for (let marker in this.monitorMarkers) {
        this.map.remove(marker);
      }
    },
    clearMonitorPoints: function () {
      this.map.remove(this.monitorMarkers);
      this.monitorMarkers = [];
    },
    removeCanvasLayers: function () {
      /*
      移除canvasLayer图层
       */
      for (let key in this.canvasLayers) {
        this.map.remove(this.canvasLayers[key]);
      }
    },
    showHideCanvasLayer: function (isCanvasShow, element) {
      /*
      显示或隐藏canvas图层
      isShow： 图层是否显示标识符，true为显示
      当isShow=true，切换按钮显示隐藏，点击后隐藏图层
      当isShow=false，切换按钮为显示，点击后显示图层
       */
      if (isCanvasShow) {
        this.canvasLayers[element].show();
      } else {
        this.canvasLayers[element].hide()
      }
    },
    drawPolygon: function (southWest, northEast) {
      let bounds = new this.tabMap.Bounds(southWest, northEast)
      let polygon = new this.tabMap.Rectangle({
        bounds: bounds,
        // strokeColor:'black',

        // strokeWeight: 6,
        // strokeOpacity:0.5,
        // strokeDasharray: [30,10],
        // strokeStyle还支持 solid
        // strokeStyle: 'dashed',
        fillColor:'#d4d4d4',
        fillOpacity: 0.5,
        // cursor:'pointer',
        zIndex: 50,
      });
      this.map.add(polygon);
      return polygon
    },
    setPolygonOptions: function (polygon, color, opacity) {
      /**
       * @Description: 设置polygon样式
       * @Auther: HLY
       * @Date: 2023/2/18
       */
      polygon.setOptions({
        fillColor: color,
        fillOpacity: opacity
      })
    },
    switchGridDetailLayer: function () {
      /**
       * @Description: 切换格网显示状态，当前为显示则切换为隐藏，反之相同。
       * @Auther: HLY
       * @Date: 2023/2/18
       */
      let gridDetail = this.polygonMap.get(this.curGridOverallId);
      gridDetail.forEach(function (polygon, gridDetailId) {
        // if (!polygon.getOptions().visible) {
        //   polygon.show();
        // } else {
        polygon.hide();
        // }
      });
    },
    clearGrid: function () {
      /**
       * @Description: 将所有格网相关polygon删除，并初始化this.polygonMap
       * @Auther: HLY
       * @Date: 2023/2/18
       */
      this.polygonMap.forEach(function (gridDetail, gridOverallId) {
        gridDetail.forEach(function (polygon, gridDetailId) {
          this.map.remove(polygon);
        })
      });
      this.polygonMap = null;
    },
    drawGrid: function (gridGap, taskId, hisOffset) {
      /**
       * @Description: 绘制格网，生成 分辨率：{格网编号：{polygon}}格式数据
       * @Auther: HLY
       * @Date: 2023/2/18
       */
      request.get('/grids/generateGrids/' + taskId + '/' + gridGap).then(res => {
        let gridData = res.data;
        console.log(gridData)
        this.polygonMap = new Map;
        let gridDetail = this.drawGridDetail(gridData.gridDetailList, hisOffset)
        this.polygonMap.set(gridData.gridOverallId, gridDetail);
        this.curGridOverallId = gridData.gridOverallId;
      });

    },
    drawGridDetail: function (gridDetailList, hisOffset) {
      /**
       * @Description: 绘制格网的小polygon，并返回格网子块：polygon的map类型数据
       * @Auther: HLY
       * @Date: 2023/2/18
       */
      let gridDetail = new Map();
      gridDetailList.forEach(item => {
        let southWest = this.coordtransform.wgs84togcj02(item.minLon + hisOffset.lngOffset, item.minLat + hisOffset.latOffset);
        let northEast = this.coordtransform.wgs84togcj02(item.maxLon + hisOffset.lngOffset, item.maxLat + hisOffset.latOffset);
        let polygon = this.drawPolygon(new this.tabMap.LngLat(southWest[0], southWest[1]),
            new this.tabMap.LngLat(northEast[0], northEast[1]));
        gridDetail.set(item.gridDetailId, polygon);
      });
      return gridDetail;
    },
    findColor: function (array, val, colors = {
      'green': [
        "#006837",
        "#1a9850",
        "#66bd63",
        "#a6d96a",
      ], 'yellow': [
        '#fff399',
        '#FFFF00',
        '#FFD700',
        '#FF8C00',
      ],
      'red': [
        // "#fdae61",
        '#FF0000',
        "#f46d43",
        "#d73027",
        "#a50026"
      ]}) {
      /**
       * @Description: 基于污染标准确定颜色
       * @Auther: HLY
       * @Date: 2023/2/19
       */
      //如果值小于revenue最小的值时，则奖励0
      if (val < Math.min.apply(null, array)) {
        let ratio = val / array[0];
        if (ratio < 0.0) ratio = 0.0;
        return colors['green'][Math.floor((colors['green'].length - 1) * ratio)];
      } else if (val > Math.max.apply(null, array)) {
        //如果值大于revenue最大的值时，则奖励最高一档
        let ratio = (val - array[1]) / array[1];
        if (ratio > 1.0) ratio = 1.0;
        return colors['red'][Math.floor((colors['red'].length - 1) * ratio)];
      } else {
        let ratio = (val - array[0]) / (array[1] - array[0]);
        return colors['yellow'][Math.floor((colors['yellow'].length - 1) * ratio)];
      }

      // var idx = 0, i = 0, j = array.length;
      // for (i; i < j; i++) {
      //   if (array[i] > val) {
      //     idx = i;
      //     break;
      //   }
      // }
      // return idx;
    },

    loadKriging: function (element, krigingData,standard,isStandard) {
      /*
      克里金插值，并写入canvas
      edges：边界坐标 [[[lng,lat],[lng,lat],[lng,lat]]] #### 通过points获取坐标范围
      points: 监测数据 [{value:'',geometry:{x:'',y:''}},{}]
       */
      console.log('element:',element,'data:',krigingData)
      let xlim = krigingData.xlim;
      let ylim = krigingData.ylim;
      let edges = [[[xlim[0], ylim[0]], [xlim[1], ylim[0]], [xlim[1], ylim[1]], [xlim[0], ylim[1]], [xlim[0], ylim[0]]]];
      let points = krigingData.data;
      let canvas = document.createElement('canvas');
      canvas.width = canvas.height = 200;
      let ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      let n = points.length;
      let t = [];//数值
      let x = [];//经度
      let y = [];//纬度

      for (let i = 0; i < n; i++) {
        t.push(points[i].value);
        // t.push((points[i].longitude-xlim[0])/(xlim[1]-xlim[0])*(points[i].latitude-ylim[0])/(ylim[1]-ylim[0])*2000);
        // t.push(i);
        x.push(points[i].longitude);
        y.push(points[i].latitude);
      }

      // console.log(t, x, y)
      // 对数据集进行训练
      let variogram = kriging.train(t, x, y, 'exponential', 0, 100);
      // console.log('variogram',variogram)
      // 改值越大，边界线越圆滑，反之锯齿越严重
      let tole = 100;
      //使用variogram对象使polygons描述的地理位置内的格网元素具备不一样的预测值,最后一个参数，是插值格点精度大小
      let grid = kriging.grid(edges, variogram, (ylim[1] - ylim[0]) / tole);
      console.log('grid', grid)
      //颜色，从小到大
      let colors = [
        "#006837",
        "#1a9850",
        "#66bd63",
        "#a6d96a",
        "#d9ef8b",
        "#ffffbf",
        "#fee08b",
        "#fdae61",
        "#f46d43",
        "#d73027",
        "#a50026"];
      // let colors = [
      // ]

      //绘制格网到canvas
      kriging.plot(canvas, grid, [xlim[0], xlim[1]], [ylim[0], ylim[1]], colors.reverse(),standard,this.findColor,isStandard);

      this.canvasLayers[element] = new this.tabMap.CanvasLayer({
        canvas: canvas,
        bounds: new this.tabMap.Bounds(
            [xlim[0], ylim[0]],
            [xlim[1], ylim[1]]
        ),
        opacity: 0.6,
        zooms: [8, 23],
      });
      this.canvasLayers[element].setMap(this.map);

      // 绿，黄绿，橙黄绿，红橙黄绿，紫红橙黄绿，洋红紫红橙黄绿
      // #01e401,#ffff01,#ff7e00,#fe0000,#98004b,#7e0123,
      // 黄绿 #ffff01  #01e401
      // 橙黄绿 #01e401 #ff7e00 #ffff01  #01e401
      // 红橙黄绿 #01e401 #fe0000 #ff7e00 #ffff01  #01e401
      // 紫红橙黄绿 #01e401 #98004b #fe0000 #ff7e00 #ffff01  #01e401
      // 洋红紫红橙黄绿 #01e401 #7e0123 #98004b #fe0000 #ff7e00 #ffff01  #01e401
      //
    },

  },
}
</script>

<style lang="less" scope>
.gaodeMap {
  width: 100%;
  height: 100%;
  //top: 2.5%;
  //margin: auto;
  //padding: 5px;
  //margin: 0 auto;
  //text-align: center;
  font-size: 20px;
  color: black;


  .searchDiv {
    position: absolute;
    z-index: 2;
    display: flex;
    width: 23%;
    left: 11%;
    padding-top: 16px;
  }

  .amap-icon img {
    width: 25px;
    height: 34px;
  }

}
</style>