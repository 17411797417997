<template>
  <div id="car-speed-chart">
    <!--    <el-container>-->
    <!--      <el-header height="40px">-->
    <div class="car-speed-chart-title">速度</div>
    <!--      </el-header>-->
    <!--      <el-main style="padding: 0">-->
    <div class="chart-container">
      <div id="gauge"></div>
    </div>
    <!--      </el-main>-->
    <!--    </el-container>-->
  </div>

</template>

<script>
import echarts from "echarts";

export default {
  name: "CarStatus",
  props: ["speed"],
  data() {
    return {
      //echarts实例

      chart: "",
      option: {
        series: [
          {
            type: 'gauge',
            radius: '60%',
            min: 0,
            max: 10, //最高速度
            axisLine: {
              lineStyle: {
                width: 4,
                color: [
                  [0.3, '#67e0e3'],
                  [0.7, '#37a2da'],
                  [1, '#fd666d']
                ]
              }
            },
            pointer: {
              itemStyle: {
                color: 'inherit'
              }
            },
            axisTick: {
              distance: -15,
              length: 4,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            splitLine: {
              distance: -15,
              length: 10,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            axisLabel: {
              show: false,
              color: 'inherit',
              distance: 15,
              fontSize: 8
            },
            detail: {
              valueAnimation: true,
              formatter: '{value} m/s',
              color: 'inherit',
              fontSize: 13
            },
            data: [
              {
                value: this.speed
              }
            ]
          }
        ]
      }
    }
  },
  mounted() {
    this.drawChart();
  },
  watch:{
    speed:function (curSpeed){
      this.drawChart();
    },
    //观察option的变化
    option: {
      handler(newVal, oldVal) {
        if (this.chart) {
          if (newVal) {
            this.chart.setOption(newVal);
          } else {
            this.chart.setOption(oldVal);
          }
        } else {
          this.init();
        }
      },
      deep: true //对象内部属性的监听，关键。

    }
  },
  methods: {
    init() {

      this.chart = this.$echarts.init(document.getElementById(this.id));

      this.chart.setOption(this.option);

    },
    drawChart: function () {
      let chart = this.$echarts.init(document.getElementById('gauge'));
      chart.setOption(this.option);
      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize', function () {
        chart.resize()
      });
    }
  }
}
</script>

<style lang="less">
#car-speed-chart {
  width: 45%;
  height: 100%;
  box-sizing: border-box;
  //margin-left: 20px;
  //background-color: rgba(6, 30, 93, 0.5);
  //border-top: 2px solid rgba(1, 153, 209, .5);
  letter-spacing: 4px;

  .car-speed-chart-title {
    //height: 40px;
    //margin-top: 15px;
    font-weight: bold;
    font-size: 20px;
    color: #00ccff;
    text-align: center;

  }

  .chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    #gauge {
      height: 200px;
      width: 200px;
    }
  }
}


</style>