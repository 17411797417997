<template>
  <div class="my-check-box">
<!--    <input v-model="check" type="checkbox"></input><label id="check-box">{{label}}</label>-->
    <el-checkbox>{{label}}</el-checkbox>
  </div>
</template>

<script>
export default {
  name: "LayerCheckBox",
  props:["label"],
  data() {
    return{
      check: false,
    }
  },
  watch:{
    check(curCheck){
      if (curCheck){
        console.log('curCheck',curCheck)
        console.log('label',this.label)
        // this.check = this.isCheck;
        this.$emit('check',this.label);
      }else{
        this.$emit('uncheck',this.label);
      }
    }
  }
}
</script>

<style lang="less">
.my-check-box{
  height: 100%;
  width: 100%;
  margin-left: 2px;
  display: flex;
  text-align: center;
  align-items: center;

  #check-box{
    color: black;
    //margin: 5px;
    font-size: 10px;
    white-space: nowrap;
  }
}
</style>