<template>
  <div id="scroll-board">
    <dv-scroll-board :config="config" :key="ChangeTimes" @click="GetContaminants"/>
  </div>

</template>

<script>
import request from "@/utils/request";
import {enums} from '@/utils/common'

export default {
  name: 'ScrollBoard',
  props: ['PointData', 'TaskID', 'type'],
  data() {
    return {
      config: {
        header: ['经度', '纬度', '状态', '查看'],
        data: [],
        index: true,
        columnWidth: [170, 300, 300, 300, 170],
        align: ['center', 'center', 'center', 'center', ''],
        rowNum: 5,
        headerBGC: '#1981f6',
        headerHeight: 30,
        indexHeader: "点号",
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)',
      },
      ChangeTimes: 0,
      DetectorData: [],
    }
  },
  mounted() {

  },
  watch: {
    PointData: {
      handler(newValue, oldValue) {
        this.changePointList(newValue);
      }
    },

    type: {
      handler(newValue, oldValue) {
        console.log(newValue);
      }
    }
    // TaskID:{
    //   handler(newValue,oldValue) {
    //     console.log(newValue);
    //     this.ChangeTimes++;
    //   }
    // }
  },
  methods: {
    changePointList: function (data) {
      this.config.data = [];
      for (let i = 0; i < data.length; i++) {
        let d = data;
        d[i][0] = Math.round(d[i][0] * 10000) / 10000;
        d[i][1] = Math.round(d[i][1] * 10000) / 10000;
        if (this.type === enums.pollutionMode.metalMode) {
          this.config.header = ['经度', '纬度', '状态', '查看'];
          this.config.columnWidth = [170, 300, 300, 300, 170];
          this.config.align = ['center', 'center', 'center', 'center', ''];
          if (d[i][2] === '已完成') this.config.data.push([d[i][0], d[i][1], '<div style="color:#11ff00">已完成</div>', '<button class="contimant">查看</button>'])
          else if (d[i][2] === '正在前往') this.config.data.push([d[i][0], d[i][1], '<div style="color:#fffc00">正在前往</div>', '  -'])
          else if (d[i][2] === '未完成') this.config.data.push([d[i][0], d[i][1], '<div style="color:#ff0000">未完成</div>', '  -'])
        } else {
          this.config.header = ['经度', '纬度', '状态'];
          this.config.columnWidth = [170, 300, 300, 300];
          this.config.align = ['center', 'center', 'center', 'center'];
          if (d[i][2] === '已完成') this.config.data.push([d[i][0], d[i][1], '<div style="color:#11ff00">已完成</div>'])
          else if (d[i][2] === '正在前往') this.config.data.push([d[i][0], d[i][1], '<div style="color:#fffc00">正在前往</div>'])
          else if (d[i][2] === '未完成') this.config.data.push([d[i][0], d[i][1], '<div style="color:#ff0000">未完成</div>'])
        }
      }
      this.ChangeTimes++;
    },

    GetContaminants: function (data) {

      if (data.columnIndex === 4) {
        console.log("查询污染物浓度：", this.TaskID);
        // console.log('/task/'+this.TaskID)
        request.get('/tasks/' + this.TaskID).then(res => {
          console.log(res);
          this.DetectorData = res.data.detectionDataList[data.rowIndex].heavyMetalDetail;
          this.$emit('ItemClick', this.DetectorData);
          // this.$nextTick(() => {
          //   this.config = { ...this.config };
          // })
        })
      }

    }
  }
}
</script>

<style lang="less">
#scroll-board {
  width: 90%;
  box-sizing: border-box;
  margin-left: 5%;
  margin-top: 3%;
  height: 100%;
  overflow: hidden;

}

.contimant {
  background-color: #0085b2;
  color: white;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #0e4b93;
  border-radius: 20px;
  width: 110%;
  height: 55%;
  margin-left: -4px;
}
</style>