<template>
  <div class="svg2-btn">
    <svg width="130" height="49" viewBox="0 0 130 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49 2H16L3 15V29.5" stroke="#00E7FF"/>
      <g filter="url(#filter0_f_9_118)">
        <path d="M15.5 2.5L3 15" stroke="#00E7FF"/>
      </g>
      <path d="M81.5 46.5L114.5 46.5L127.5 33.5L127.5 19" stroke="#00E7FF"/>
      <g filter="url(#filter1_f_9_118)">
        <path d="M115 46L127.5 33.5" stroke="#00E7FF"/>
      </g>
      <mask id="path-5-inside-1_9_118" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 15.8472V44H114.232L125.5 32.7319V4H17.3472L5.5 15.8472Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 15.8472V44H114.232L125.5 32.7319V4H17.3472L5.5 15.8472Z" fill="#00E7FF" fill-opacity="0.1"/>
      <path d="M5.5 15.8472L4.79289 15.1401L4.5 15.433V15.8472H5.5ZM5.5 44H4.5V45H5.5V44ZM114.232 44V45H114.646L114.939 44.7071L114.232 44ZM125.5 32.7319L126.207 33.439L126.5 33.1461V32.7319H125.5ZM125.5 4H126.5V3H125.5V4ZM17.3472 4V3H16.933L16.6401 3.29289L17.3472 4ZM4.5 15.8472V44H6.5V15.8472H4.5ZM5.5 45H114.232V43H5.5V45ZM114.939 44.7071L126.207 33.439L124.793 32.0248L113.525 43.2929L114.939 44.7071ZM126.5 32.7319V4H124.5V32.7319H126.5ZM125.5 3H17.3472V5H125.5V3ZM16.6401 3.29289L4.79289 15.1401L6.20711 16.5543L18.0543 4.70711L16.6401 3.29289Z" fill="#00E7FF" mask="url(#path-5-inside-1_9_118)"/>
      <rect x="18.5" y="6" width="2" height="1" fill="#00E7FF"/>
      <rect x="21.5" y="6" width="2" height="1" fill="#00E7FF"/>
      <rect x="24.5" y="6" width="2" height="1" fill="#00E7FF"/>
      <rect x="104.5" y="40" width="2" height="1" fill="#00E7FF"/>
      <rect x="107.5" y="40" width="2" height="1" fill="#00E7FF"/>
      <rect x="110.5" y="40" width="2" height="1" fill="#00E7FF"/>
      <line x1="124.5" y1="5.5" x2="82.5" y2="5.5" stroke="#00E7FF"/>
      <line x1="124" y1="5" x2="124" y2="23" stroke="#00E7FF"/>
      <defs>
        <filter id="filter0_f_9_118" x="0.646484" y="0.146484" width="17.207" height="17.207" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_9_118"/>
        </filter>
        <filter id="filter1_f_9_118" x="112.646" y="31.1465" width="17.207" height="17.207" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_9_118"/>
        </filter>
      </defs>
    </svg>
    <a href="#" id="btn-txt"><span >{{ text }}</span></a>
  </div>
</template>

<script>
export default {
  name: "Btn2",
  props:['text']
}
</script>

<style lang="less">
.svg2-btn{
  margin: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  #btn-txt{
    position: absolute;
    z-index: 1;
    color: #00E7FF;
    text-decoration: none;
    letter-spacing: 3px;
    text-indent: 3px;

  }
  #btn-txt:hover{
    color: #fff;
  }

}
</style>