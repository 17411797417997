<template>
  <div class="svg1-btn">
    <svg width="124" height="25" viewBox="0 0 124 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.7129 0.5H17.2929L5.14645 12.6464L4.79289 13L5.14645 13.3536L16.2929 24.5H12.2071L0.700243 12.9931L12.7129 0.5Z" stroke="#00E7FF"/>
      <path d="M102.787 0.5H98.2071L110.354 12.6464L110.707 13L110.354 13.3536L99.2071 24.5H103.293L114.8 12.9931L102.787 0.5Z" stroke="#00E7FF"/>
      <path d="M111 0H105L118 13L106 25H111.5L123.5 13L111 0Z" fill="#00E7FF"/>
      <path d="M20.7129 0.5H25.2929L13.1464 12.6464L12.7929 13L13.1464 13.3536L24.2929 24.5H20.2071L8.70024 12.9931L20.7129 0.5Z" fill="#00E7FF" stroke="#00E7FF"/>
      <path d="M28.3043 0H106.481L121 12.5L106.481 25H28.3043C9.31836 18 6.5265 10.5 28.3043 0Z" fill="#00E7FF" fill-opacity="0.1"/>
    </svg>
    <a href="#" id="btn-txt"><span >{{ text }}</span></a>
  </div>

</template>

<script>
export default {
  name: "Btn1",
  props:['text']
}
</script>

<style lang="less">
.svg1-btn{
  //position: relative;
  //height: 50px;
  margin: 10px 10px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  #btn-txt{
    //width: 50px;
    //height: 20px;
    position: absolute;
    z-index: 1;
    color: #00E7FF;
    //opacity: 0.7;
    text-decoration: none;
    letter-spacing: 6px;
    text-indent: 6px;
    //font-size: 12px;
  }
  #btn-txt:hover{
    color: #fff;
  }
}
</style>