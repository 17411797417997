<template>

</template>

<script>
// const ServerURL = "43.138.14.5:9090";
// const ServerURL= "192.168.3.10:9090";
// const ServerURL="121.37.25.25:9090";
const ServerURL="120.55.64.56:9090";
export default {
  name: "Code",
  ServerURL,
  data() {
    return {
      MESSAGE_TYPE_ORDER_MONITOR_FORWARD : 1001,//"机器人前进"
      MESSAGE_TYPE_ORDER_MONITOR_BACKOFF : 1002,//"机器人后退"
      MESSAGE_TYPE_ORDER_MONITOR_STOP : 1003,//"机器人停止移动"
      MESSAGE_TYPE_ORDER_ELEVATOR_UP : 1004,//"升降机上升"
      MESSAGE_TYPE_ORDER_ELEVATOR_DOWN : 1005,//"升降机下降"
      MESSAGE_TYPE_ORDER_ELEVATOR_STOP : 1006,//"升降机停止"
      MESSAGE_TYPE_ORDER_HEAVY_MENTAL_RESTART : 1007,//"重金属仪重新开始测量"
      MESSAGE_TYPE_ORDER_OCR_REMAKE : 1008,//"OCR重新拍摄照片"
      MESSAGE_TYPE_ORDER_OCR_REREAD : 1009,//"OCR重新读取数据"
      MESSAGE_TYPE_ORDER_RASPBERRY_RESTART : 1010,//"树莓派重新启动"

      MESSAGE_TYPE_NEW_TASK: 2001,
      MESSAGE_TYPE_NEW_DETECTION_DATA: 3001, //小车上传检测数据
      MESSAGE_TYPE_NEW_MONITOR_STATE: 3002,
      MESSAGE_TYPE_ACTIVE_TASK_STATE: 3003,
      MESSAGE_TYPE_FINISH_TASK_FLAG: 3004,
      SAVE_OK: 20011,
      SAVE_ERR: 20010,
      DELETE_OK: 20021,
      DELETE_ERR: 20020,
      UPDATE_OK: 20031,
      UPDATE_ERR: 20030,
      GET_OK: 20041,
      GET_ERR: 20040,


    }
  }
}
</script>

<style scoped>

</style>