<template>
  <div class="mile-details">
    <div style="color: #00ccff;text-align: center">行驶里程（米）</div>
    <div id="card-box">
      <div class="card">{{ Math.floor(miles / 1000) ? Math.floor(miles / 1000) : 0 }}</div>
      <div class="card">{{ Math.floor(miles % 1000 / 100) ? Math.floor(miles % 1000 / 100) : 0 }}</div>
      <div class="card">{{ Math.floor(miles % 100 / 10) ? Math.floor(miles % 100 / 10) : 0 }}</div>
      <div class="card">{{ Math.floor(miles % 10) ? Math.floor(miles % 10) : 0 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Mileage",
  props: ['miles'],
}
</script>

<style lang="less">
.mile-details {
  height: 120px;
  width: 100%;
  //display: flex;
  //justify-content: center;
  font-size: 20px;
  //align-items: center;
  flex-direction: column;

  #card-box {
    display: flex;
    margin-top: 20px;

    .card {
      background-color: rgba(4, 49, 128, .6);
      //color: #08e5ff;
      color: #fff;
      height: 45px;
      width: 45px;
      font-size: 35px;
      font-weight: bold;
      line-height: 50px;
      text-align: center;
      margin: 5px;
    }
  }


}
</style>