<template>
<!--  <div >-->
    <dv-border-box-9 id="image-container">
      <el-container style="width: 100%;height: 100%" id="image-border">
        <el-header height="30px" id="image-header">{{ cameraTitle }}</el-header>
        <el-main id="image-box">
          <el-image
              id="camera-image"
              style="width: 90%; height: 90%;display: flex;justify-content: center;align-items: center;"
              :src="myUrl "
              fit="contain">
            <div slot="error"
                 style="display: flex;justify-content: center;align-items: center;color: #fff">
              <span>设备未启动</span>
            </div>
          </el-image>
        </el-main>
      </el-container>
    </dv-border-box-9>
<!--  </div>-->

</template>

<script>
export default {
  name: "CameraImage",
  props: ['myUrl', 'cameraTitle'],
  data() {
    return {}
  },
  computed: {
    containerStyle: function () {
      // return {"width": this.width + "px", "height": this.height + "px"}
      return {"width": this.width + "%", "height": this.height + "%"}
    },
    imgStyle: function () {
      return {
        "width": this.width - 60 + "px",
        "height": this.height - 80 + "px",
        "max-height": this.height - 80 + "px",
        "max-width": this.width - 60 + "px"
      }
    }
  }
}
</script>

<style lang="less">
* {
  font-family: "黑体";
  font-weight: bold;
}

#image-container {

  //width: 300px;
  //height: 300px;
  width: 100%;
  height: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00ccff;

  #image-border {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #image-header {
    margin: 10px;
    padding-top: 10px;
    letter-spacing: 6px;
  }

  #image-box {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    #camera-image {
      width: 95%;
    }
  }


}
</style>