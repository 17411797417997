<template>
  <div class="left-chart-3" style="">
    <div class="lc3-header" style="font-size: larger;font-weight: bold">巡检任务</div>
    <div class="lc2-select">
      <span>当前任务：{{ TaskName }}</span>
      <span style="margin-top: 10px">路线长度：{{ AllLength }}米</span>
    </div>
    <div style="align-items: center;margin:20px 0 0 20px;">
      <dv-percent-pond :config="config" style="width:95%;height:30px;"/>
    </div>

    <div class="lc3-header" style="font-size: larger;font-weight: bold;">节点列表</div>
    <PointTable v-bind:PointData="PointData" v-bind:TaskID="taskId" @ItemClick="GetDetectorData" v-bind:type="tasktype"></PointTable>
  </div>

</template>

<script>
import request from "@/utils/request";
import CenterMap from "@/components/CenterMap";
import PointTable from "@/components/PointTable";
import Contaminants from "@/components/Contaminants";

export default {
  name: "RightBottomChart",
  components: {CenterMap, PointTable, Contaminants},
  props: ['taskId', 'taskProcess', 'monitorUrl', 'PointData', 'AllLength', 'TaskName','tasktype'],
  data() {
    return {
      config: {
        value: 0
      },
      options: [],
      // taskId: this.taskId,
      taskName: '',
      TaskID: 0,
      HeavyMetal: [],
      ChangeTime: 0,
      TKID:-1,//传给PointTable
    }
  },
  watch: {
    taskProcess() {
      this.config = {value: this.taskProcess};
    },
    HeavyMetal(item1, item2) {
      this.$emit('ChangeHeavyMetal', item1);
    },
    PointData() {
    },
    taskId(){
    }
  },
  mounted() {
    // this.getActiveTasks();
  },
  methods: {
    // getActiveTasks:function (){
    //   // 获取正在进行的任务数据,并更新到select中
    //   request.get('/tasks/activeTasks').then(res => {
    //     // console.log(res)
    //     if (res.data !==null){
    //       this.options = res.data;
    //       this.options = {...this.options};
    //       console.log(this.taskId)
    //       if (this.taskId!==-1){
    //         this.taskName=this.options[this.taskId].taskName;
    //       }else {
    //         this.taskName='无任务';
    //       }
    //     }
    //   })
    //   // const keys=Object.keys(this.options)
    //   // this.taskName=this.options[keys[0]].taskName
    //   // this.taskName=this.options[this.taskId].taskName;
    // },
    selectTask(taskId) {
      this.$emit('initAll')
      if (typeof taskId === "string") {
        //  未选择任务
        // this.updateConfig(0);
        this.updateConfig(this.taskProcess);
      } else {

        // this.updateConfig(this.options[taskId].taskProcess);
        this.updateConfig(this.taskProcess);
        request.get('/tasks/' + taskId).then(res => {
          let taskData = res.data;
          //地图显示
          this.$emit('drawHisTask', taskData.detectionPointList);
          this.$emit('drawMonitor', taskData.detectionDataList);

        })
      }
    },
    getTaskById: function (taskId) {
      request.get('/tasks/' + taskId).then(res => {
        return res.data;
      })
    },
    updateConfig: function (processPercent) {
      // 更新水位图数据
      this.config.value = [processPercent];
      this.config = {...this.config};
    },
    GetDetectorData(item) {
      this.HeavyMetal = item;
    }
  }
}
</script>

<style lang="less">
.left-chart-3 {
  width: 100%;
  height: 48%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;


  .lc3-header {
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    text-indent: 20px;
    margin-top: 10px;
    letter-spacing: 4px;
  }

  .lc3-details {
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;

    }
  }

  .lc2-select {
    height: 40px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    margin: 13px 0 0 20px;
    letter-spacing: 4px;

    .el-input__inner {
      background-color: #030409;
      color: #fff;
      border: 1px solid #0e4b93;
    }

    .el-select-dropdown {
      background-color: #030409;
      border: 1px solid #0e4b93;

      .el-select-dropdown__item {
        color: white;
        background-color: #030409;
        text-align: left;
      }

      .el-select-dropdown__item:hover {
        background-color: rgba(0, 225, 219, 0.690196078431373);
      }
    }
  }
}

.dv-percent-pond text {
  font-size: 20px;
}
</style>